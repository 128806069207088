/*
 * imports
 */
import './styles.css';
import configModule from '../../../config.module';
import { memo, useEffect, useState } from 'react';
// router imports
import { useNavigate } from 'react-router-dom';
import usePath from '../../../Hooks/use-path';
// components
import UserDrawer from './UserDrawer';
// redux imports
import { useSelector } from 'react-redux';
// blueprintjs imports
import { Navbar, Button, Alignment, NavbarDivider } from '@blueprintjs/core';
// i18next import
import { useTranslation } from 'react-i18next';
// types
import type { FCWithChildren } from '../../../types';
import type { RootState } from '../../../Store/Redux/store.types';
import type { User } from '../../../Store/Redux/users/types';
import type { SystemConfiguration } from '../../../Store/Redux/system/types';
/* *
import logger from '@infobiotech/js-logger';
const componentName = 'PageHeader';
logger.setLogLevel(logger.INFO, componentName);
/* */
/**
 * Page header component
 */
const PageHeader: FCWithChildren = memo(() => {
  /*
   * Redux store
   */
  const authenticationUser = useSelector<RootState, User | null | undefined>(
    state => state.authentication.authenticationUser
  );
  const systemConfiguration = useSelector<RootState, SystemConfiguration>(
    state => state.system.systemConfiguration
  );
  /**
   * Local state
   */
  const { controller: currController, entityPath } = usePath();
  const navigate = useNavigate();
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const [hiMessage, setHiMessage] = useState<string | null>(null);
  /**
   * i18n translations
   */
  const {
    t,
    // i18n,
  } = useTranslation();
  /* *
  const{ language } = i18n;
  const changeLanguage = lang => {
    logger.info(componentName, 'changeLanguage', { lang });
    i18n.changeLanguage(lang);
  };
  /* */
  const buttonPropsForPath = (path: string) => {
    return {
      onClick: () => {
        if (currController !== path || (currController === path && entityPath)) {
          navigate(`/${path}`);
        }
      },
      disabled: currController === path && !entityPath,
      active: currController === path && !entityPath,
    };
  };
  /*
   * handlers
   */
  const drawerCloseHandler = () => {
    setDrawerOpened(false);
  };
  const appNameClickHandler = () => {
    navigate(`/${configModule.controllers.defaultAfterSignIn}`);
  };
  const toggleDrawerOpened = () => {
    setDrawerOpened(drawerOpened => !drawerOpened);
  };
  /* *
  const drawerOpenedHandler = () => {
    // nothing for now
  }
  /** */
  /*
   * useEffect
   */
  useEffect(() => {
    if (currController) {
      const controllers = configModule.controllers;
      let name = authenticationUser?.displayName;
      if (name) {
        name = name.split(' ')[0];
      }
      if (controllers.public.includes(currController)) {
        setHiMessage('Welcome!');
      } else if (controllers.signIn === currController) {
        setHiMessage('Join us!');
      } else if (controllers.signedIn.includes(currController)) {
        if (authenticationUser?.isAnonymous) {
          setHiMessage('Hi guest! You can sign up to the right!');
        } else {
          setHiMessage(`Hi ${name || 'guest'}, It is a pleasure to have you here.`);
        }
      } else if (controllers.unauthorized === currController) {
        setHiMessage(null); // or 'Oops. Too bad you cannot access here.'
      } else if (controllers.superUser.includes(currController)) {
        setHiMessage(`Hi ${name || 'superuser'}`);
      }
    } else {
      setHiMessage(null);
    }
  }, [currController, authenticationUser]);
  /*
   * content
   */
  const userDrawerContent = (
    <UserDrawer
      isAnonymous={authenticationUser?.isAnonymous || false}
      controller={currController || ''}
      onClose={drawerCloseHandler}
    />
  );
  /*
   * render
   */
  return (
    <>
      {drawerOpened && userDrawerContent}
      <Navbar>
        <Navbar.Group>
          <Button minimal={true} text={t('app.name')} onClick={appNameClickHandler} />
          <NavbarDivider />
          <div className="hi-message">{hiMessage}</div>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {authenticationUser ? (
            <>
              {authenticationUser.accessControl?.badgeWriters && (
                <>
                  <Button icon="dashboard" {...buttonPropsForPath('badgeWriters')} />
                  <NavbarDivider />
                </>
              )}
              <Button intent={'primary'} icon="user" onClick={toggleDrawerOpened} />
            </>
          ) : (
            <Button
              intent="primary"
              minimal={true}
              icon="log-in"
              text={`${t('signIn.label')}`}
              outlined={true}
              {...buttonPropsForPath('signIn')}
            />
          )}
          {systemConfiguration?.signUp && (!authenticationUser || authenticationUser.isAnonymous) && (
            <>
              <NavbarDivider />
              <Button
                intent="primary"
                icon="new-person"
                text={`${t('signUp.label')}`}
                {...buttonPropsForPath('signUp')}
              />
            </>
          )}
        </Navbar.Group>
      </Navbar>
    </>
  );
});
/*
 * export
 */
export default PageHeader;
