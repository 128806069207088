/*
 * imports
 */
import { createSlice } from '@reduxjs/toolkit';
/*
 * types
 */
import type { Payload } from '../store.types';
import type { BadgeWritersState, BadgeWriters, Blink } from './types';
/**
 * initial state
 */
const badgeWritersInitialState: BadgeWritersState = {
  badgeWriters: {},
  badgeWritersMillis: 0,
  // badgeWritersInvitingBadgeWriter: null,
  // badgeWritersDeletingBadgeWriter: null,
};
/**
 * badgeWriters slice
 */
const badgeWritersSlice = createSlice({
  name: 'badgeWriters',
  initialState: badgeWritersInitialState,
  reducers: {
    badgeWritersMerge(
      state: BadgeWritersState,
      action: Payload<{ badgeWriters: BadgeWriters; millis: number }>
    ) {
      const { badgeWriters, millis } = action.payload;
      state.badgeWriters = { ...state.badgeWriters, ...badgeWriters };
      state.badgeWritersMillis = millis;
    },
    /* *
    invitingBadgeWriter(state: BadgeWritersState, action: Payload<{invitingBadgeWriter: boolean}> ) {
      const { invitingBadgeWriter } = action.payload;
      state.badgeWritersInvitingBadgeWriter = invitingBadgeWriter;
    }
    /* *
    deletingBadgeWriter(state: BadgeWritersState, action: Payload<{badgeWriterId: string}>) {
      const { badgeWriterId } = action.payload;
      state.badgeWritersDeletingBadgeWriter = badgeWriterId;
    }
    /* */
    unsetBadgeWriter(
      state: BadgeWritersState,
      action: Payload<{ badgeWriterId: string; millis: number }>
    ) {
      const { badgeWriterId, millis } = action.payload;
      delete state.badgeWriters[badgeWriterId];
      state.badgeWritersMillis = millis;
    },
    badgeWritersUnset(state: BadgeWritersState, action: Payload<{ millis: number }>) {
      const { millis } = action.payload;
      state.badgeWriters = badgeWritersInitialState.badgeWriters;
      state.badgeWritersMillis = millis;
    },
    badgeWriterSetBlink(
      state: BadgeWritersState,
      action: Payload<{ blinkProperties: Blink | null; badgeWriterId: string }>
    ) {
      const { blinkProperties, badgeWriterId } = action.payload;
      if (state.badgeWriters[badgeWriterId]) {
        state.badgeWriters[badgeWriterId].blink = blinkProperties;
      }
    },
  },
});
/*
 * exports
 */
export const badgeWritersActions = badgeWritersSlice.actions;
export default badgeWritersSlice.reducer;
