/*
 * imports
 */
import { useEffect, useState } from 'react';
// components
import IBTLoadingSpinner from '../../Components/IBTLoadingSpinner';
// pages
import Page from '../../Components/Page';
// blueprintjs import
import { ProgressBar, Callout } from '@blueprintjs/core';
// types
import type { FCWithChildren } from '../../../types';
import type { LoadingPageProps } from './types';
/**
 * global variables
 */
let mounted: boolean;
const timeUnit = 1500;
/**
 * Loading Page
 */
const LoadingPage: FCWithChildren<LoadingPageProps> = props => {
  const [status, setStatus] = useState<string>('');
  useEffect(() => {
    mounted = true;
    setTimeout(checkSlowness, timeUnit);
    return () => {
      mounted = false;
    };
  }, []);
  const checkSlowness = () => {
    if (mounted) {
      setStatus('slowness');
      setTimeout(checkIssue, timeUnit * 4);
    }
  };
  const checkIssue = () => {
    if (mounted) {
      setStatus('issue');
    }
  };
  const layout = {
    fullWidth: true,
    withPadding: true,
    centered: true,
  };
  /*
   * content
   */
  const statusIssueContent = (
    <div className="m-3 p-3">
      <Callout intent={'danger'} title={'Unexpected issue'} className="mb-2">
        <p>
          Sorry, but the system took too much time to load the page. This may be due to a temporary
          unexpected issue.
        </p>
        <p>Please restart from the home page and try again.</p>
        <a href="/" role="button" className="bp3-button bp3-intent-danger bp3-outlined mb-3">
          Home page
        </a>
        <p>If the issue persists, contact our user support.</p>
        <a
          href="/UserSupport"
          role="button"
          className="bp3-button bp3-intent-danger bp3-outlined mb-3"
        >
          User support
        </a>
      </Callout>
    </div>
  );
  const statusSlownessContent = (
    <div className="m-4 p-4">
      <Callout intent={'warning'} title={'Slowness detected'} className="mb-2">
        <p>The system is taking more time than usual to process and fulfill your request.</p>
        <p>Please wait a little bit more while we try to handle this as best as we can.</p>
        <ProgressBar intent={'warning'} />
      </Callout>
    </div>
  );
  const statusLoadingContent = (
    <div className="m-5 p-5">
      <IBTLoadingSpinner message={props.message || 'Loading...'} />
    </div>
  );
  /*
   * render
   */
  return (
    <Page layout={layout}>
      {status === 'issue'
        ? statusIssueContent
        : status === 'slowness'
        ? statusSlownessContent
        : statusLoadingContent}
    </Page>
  );
};
/*
 * export
 */
export default LoadingPage;
