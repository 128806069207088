/*
 * imports
 */
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToasterContext } from './Store/Context/Toaster';
// controller imports
import HomeController from './Controllers/HomeController';
import NotFoundController from './Controllers/NotFoundController';
import AboutController from './Controllers/AboutController';
import ErrorController from './Controllers/ErrorController';
import UnauthorizedController from './Controllers/UnauthorizedController';
// import BadgeWritersController from './Controllers/BadgeWritersController';
// import ReAuthController from './Controllers/ReAuthController';
// import SettingsController from './Controllers/SettingsController';
// import SignInController from './Controllers/SignInController';
// import SignUpController from './Controllers/SignUpController';
// import SignOutController from './Controllers/SignOutController';
// import SystemController from './Controllers/SystemController';
// import UserBadgeController from './Controllers/UserBadgeController';
// import UserController from './Controllers/UserController';
// import UsersController from './Controllers/UsersController';
// lazy imports
const BadgeWritersController = lazy(() => import('./Controllers/BadgeWritersController'));
const BadgeWritersDetailController = lazy(
  () => import('./Controllers/BadgeWritersDetailController')
);
const ReAuthController = lazy(() => import('./Controllers/ReAuthController'));
const SettingsController = lazy(() => import('./Controllers/SettingsController'));
const SignInController = lazy(() => import('./Controllers/SignInController'));
const SignUpController = lazy(() => import('./Controllers/SignUpController'));
const SystemController = lazy(() => import('./Controllers/SystemController'));
const UserController = lazy(() => import('./Controllers/UserController'));
const UsersController = lazy(() => import('./Controllers/UsersController'));
const SignOutController = lazy(() => import('./Controllers/SignOutController'));
const DevicesController = lazy(() => import('./Controllers/DevicesController'));
// pages imports
import LoadingPage from './UI/Pages/LoadingPage';
// guards imports
import SignedInGuard from './Guards/SignedInGuard';
import SignedUpGuard from './Guards/SignedUpGuard';
import NotSignedInGuard from './Guards/NotSignedInGuard';
import AdminGuard from './Guards/AdminGuard';
import PrivacyTermsGuard from './Guards/PrivacyTermsGuard';
import AccessControlGuard from './Guards/AccessControlGuard';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { badgeWritersMount } from './Store/Redux/badgeWriters/actions';
import { usersSetup } from './Store/Redux/users/actions';
import { rolesSetup } from './Store/Redux/roles/actions';
// types
import type { FCWithChildren } from './types';
import type { AppDispatch, RootState } from './Store/Redux/store.types';
import type { AuthenticationState } from './Store/Redux/authentication/types';
import type { SystemState } from './Store/Redux/system/types';
/*
 * global
 */
let badgeWritersInit = true;
let usersInit = true;
let isNavigate = false;
/*
 * App Component
 */
const App: FCWithChildren = () => {
  /*
   * redux
   */
  const { authenticationUser, authenticationUserMillis } = useSelector<
    RootState,
    AuthenticationState
  >(state => state.authentication);
  const { systemMillis, systemPolicies } = useSelector<RootState, SystemState>(
    state => state.system
  );
  const dispatch = useDispatch<AppDispatch>();
  // local state
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const toasterCtx = useContext(ToasterContext);
  // useEffects
  useEffect(() => {
    console.log('useEffect App', { authenticationUserMillis, systemMillis });
    if (authenticationUserMillis && systemMillis) {
      setIsLoading(false);
      if (authenticationUser) {
        // I want to check if privacy millis are updated
        if (systemPolicies.privacyMillis !== null) {
          // if system policies are already retrieved from database (is not null)
          if (authenticationUser.privacyMillis < systemPolicies.privacyMillis) {
            navigate('/user/privacy');
            // already went to privacy
            isNavigate = true;
          }
        }
        // I want to check if terms millis are updated
        // isNavigate is true if already navigated to privacy, though we don't want double navigate to terms
        if (systemPolicies.termsMillis !== null && !isNavigate) {
          // if system policies are already retrieved from database (is not null)
          if (authenticationUser.termsMillis < systemPolicies.termsMillis) {
            navigate('/user/terms');
          }
        }
        // no need of this variable from this point on
        isNavigate = false;
        // users setup
        if (usersInit) {
          // I want users setup after I have logged-in and authenticated and
          // only if they are super users
          if (authenticationUser.isSuperUser) {
            dispatch(usersSetup(toasterCtx));
            dispatch(rolesSetup(toasterCtx));
          }
          usersInit = false;
        }
        // badgeWriters mount
        if (badgeWritersInit) {
          // I want badgeWriters mount after I have already logged-in (and authenticated) the user.
          dispatch(badgeWritersMount(toasterCtx));
          badgeWritersInit = false;
        }
      } else {
        // if authenticationUser is false (user has not logged-in yet or logged-out)
        badgeWritersInit = true;
        usersInit = true;
      }
    } else {
      setIsLoading(true);
    }
  }, [authenticationUserMillis, authenticationUser, systemMillis]);
  /*
   * render
   */
  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace={true} />} />
        <Route path="home" element={<PrivacyTermsGuard controller={<HomeController />} />} />
        <Route path="about" element={<AboutController />} />
        <Route
          path="badgeWriters/:id"
          element={
            <AccessControlGuard
              acl={['badgeWriters']}
              permissions={['get']}
              controller={<BadgeWritersDetailController />}
            />
          }
        />
        <Route
          path="badgeWriters"
          element={
            <AccessControlGuard acl={['badgeWriters']} controller={<BadgeWritersController />} />
          }
        />
        <Route path="reAuth" element={<SignedUpGuard controller={<ReAuthController />} />} />
        <Route
          path="settings/:edit"
          element={<SignedUpGuard controller={<SettingsController />} />}
        />
        <Route path="settings" element={<SignedUpGuard controller={<SettingsController />} />} />
        <Route path="signIn" element={<NotSignedInGuard controller={<SignInController />} />} />
        <Route
          path="signUp"
          element={<NotSignedInGuard controller={<SignUpController />} guest={true} />}
        />
        <Route path="signOut" element={<SignedInGuard controller={<SignOutController />} />} />
        <Route path="system" element={<AdminGuard controller={<SystemController />} />} />
        <Route path="user/:edit" element={<SignedUpGuard controller={<UserController />} />} />
        <Route path="user" element={<SignedUpGuard controller={<UserController />} />} />
        <Route
          path="users/add"
          element={<AdminGuard controller={<UsersController addingUser={true} />} />}
        />
        <Route path="users/:id" element={<AdminGuard controller={<UsersController />} />} />
        <Route path="users" element={<AdminGuard controller={<UsersController />} />} />
        <Route
          path="devices/add"
          element={<AdminGuard controller={<DevicesController addingDevice={true} />} />}
        />
        <Route path="devices/:id" element={<AdminGuard controller={<DevicesController />} />} />
        <Route path="devices" element={<AdminGuard controller={<DevicesController />} />} />
        <Route path="error" element={<ErrorController />} />
        <Route
          path="unauthorized"
          element={<SignedInGuard controller={<UnauthorizedController />} />}
        />
        <Route path="*" element={<NotFoundController />} />
      </Routes>
    </Suspense>
  );
};
/*
 * export
 */
export default App;
