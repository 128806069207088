/*
 * imports
 */
import { badgeWritersActions } from './slice';
// new API
import { api } from '../../../Vendors/api';
// firebase imports
import {
  // firebase
  // firebaseFunctions,
  // firebaseFunctionsHttpsCallable,
  // firestore
  firestore,
  firestoreCollection,
  firestoreOnSnapshot,
} from '../../../Vendors/firebase';
// constants and utilities imports
import { isValidKey } from '../../../Classes/IbtUtils';
// types
import type { RootState, UtilFunctions } from '../store.types';
import type { Blink, BadgeWriterCreateData, BadgeWriters, BadgeWriterUpdatable } from './types';
import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';
import type { ToasterContextObj } from '../../Context/Toaster/types';
import type {
  FirestoreUnsubscribe,
  // FirebaseHttpsCallableResult,
} from '../../../Vendors/firebase/types';
import type { HttpResponse } from '../../../Classes/IbtHttp/types';
/*
 *
 *
import logger from '@infobiotech/js-logger';
const componentName = 'badgeWritersActions';
logger.setLogLevel(logger.INFO, componentName);
/*
 * init
 */
let badgeWritersUnsubscribe: FirestoreUnsubscribe | null = null;
export const badgeWritersDetachUnsubscribe = () => {
  if (badgeWritersUnsubscribe) {
    badgeWritersUnsubscribe();
    badgeWritersUnsubscribe = null;
  }
};
/** collections' unsubscribes map */
let badgeWritersCollectionUnsubscribeMap = new Map<string, FirestoreUnsubscribe>();
export const badgeWritersDetachCollectionUnsubscribeMap = () => {
  if (badgeWritersCollectionUnsubscribeMap.size) {
    badgeWritersCollectionUnsubscribeMap.forEach((unsubscribe, key) => {
      unsubscribe();
      badgeWritersCollectionUnsubscribeMap.delete(key);
    });
    badgeWritersCollectionUnsubscribeMap = new Map<string, FirestoreUnsubscribe>();
  }
};
/**
 * badgeWritersMount it is called only at first when initializing all badgeWriters
 *  and getting them from firestore
 */
export const badgeWritersMount = ({
  firestoreErrorHandler,
}: ToasterContextObj): ThunkAction<void, RootState, any, AnyAction> => {
  return async (dispatch, getState) => {
    /* *
    logger.info(componentName, 'badgeWritersMount');
    /* */
    badgeWritersDetachUnsubscribe();
    const authenticationUser = getState().authentication.authenticationUser;
    const userId = authenticationUser?.userId || null;
    if (userId) {
      badgeWritersUnsubscribe = firestoreOnSnapshot(
        firestoreCollection(firestore, 'badgeWriters'),
        // .where('type', '==', 'badgeWriter')
        // .where('owner', '==', userId)
        snapshot => {
          let badgeWriters: BadgeWriters = {};
          snapshot.docChanges().forEach(change => {
            const document = change.doc.data();
            const documentId = change.doc.id;
            // if it's not a md5 just ignore it
            if (isValidKey(documentId)) {
              if (change.type === 'added') {
                badgeWriters = {
                  ...badgeWriters,
                  [documentId]: {
                    ...document,
                    id: documentId,
                    updatedMillis: document.updatedMillis || 0,
                    clientId: document.clientId,
                    ibtModel: document.ibtModel || -1,
                    label: document.label,
                    connected: document.connected,
                    connectedMillis: document.connectedMillis,
                    creationMillis: document.creationMillis || 0,
                    reserved: document.reserved || false,
                    reservedMillis: document.reservedMillis || 0,
                    enabled: document.enabled || false,
                    enabledMillis: document.enabledMillis || 0,
                    canBlink: document.canBlink || false,
                    blink: null,
                    badgeByteArray: document.badgeByteArray || [],
                    badgeData: document.badgeData || {},
                    badgeMillis: document.badgeMillis || 0,
                    error: document.error || null,
                    errorMillis: document.errorMillis || 0,
                    readBadgeMillis: document.readBadgeMillis || 0,
                    readBadgeStatus: document.readBadgeStatus || null,
                    writeBadgeMillis: document.writeBadgeMillis || 0,
                    writeBadgeStatus: document.writeBadgeStatus || null,
                  },
                };
              } else if (change.type === 'modified') {
                badgeWriters = {
                  ...badgeWriters,
                  [documentId]: {
                    ...document,
                    id: documentId,
                    updatedMillis: document.updatedMillis || 0,
                    clientId: document.clientId,
                    ibtModel: document.ibtModel || -1,
                    label: document.label,
                    connected: document.connected,
                    connectedMillis: document.connectedMillis,
                    creationMillis: document.creationMillis || 0,
                    reserved: document.reserved || false,
                    reservedMillis: document.reservedMillis || 0,
                    enabled: document.enabled || false,
                    enabledMillis: document.enabledMillis || 0,
                    canBlink: document.canBlink || false,
                    blink: null,
                    badgeByteArray: document.badgeByteArray || [],
                    badgeData: document.badgeData || {},
                    badgeMillis: document.badgeMillis || 0,
                    error: document.error || null,
                    errorMillis: document.errorMillis || 0,
                    readBadgeMillis: document.readBadgeMillis || 0,
                    readBadgeStatus: document.readBadgeStatus || null,
                    writeBadgeMillis: document.writeBadgeMillis || 0,
                    writeBadgeStatus: document.writeBadgeStatus || null,
                  },
                };
              } else if (change.type === 'removed') {
                dispatch(_unsetBadgeWriter(documentId));
              }
            }
          });
          if (Object.keys(badgeWriters).length) {
            dispatch(_mergeBadgeWriters(badgeWriters));
          }
        },
        firestoreErrorHandler({
          cleanupFn: () => {
            /* *
            logger.error(componentName, 'badgeWritersUnsubscribe', {
              code: snapshotError?.code,
              error: snapshotError,
              message: snapshotError?.message,
              name: snapshotError?.name,
            });
            /* */
            badgeWritersDetachUnsubscribe();
            // @todo force a reload ?
          },
        })
      );
    }
  };
};
/**
 * badgeWriters un-mount, unsubscribe badgeWriters change, dispatch `unsetBadgeWriters`
 */
export const badgeWritersUnmount = (): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    /* *
    logger.info(componentName, 'badgeWritersUnmount');
    /* */
    badgeWritersDetachUnsubscribe();
    dispatch(_unsetBadgeWriters());
  };
};
/*
 * blink actions
 */
const defaultBlink: Blink = {
  color: {
    r: 255,
    g: 255,
    b: 255,
  },
  onTime: 250,
  totalTime: 500,
  numberOfBlinks: 6,
};
/**
 * Make `badgeWriterId` blink with `blink` configurations
 *
 * `blink` has a default value of `{rgb: '255;255;255', onTime: 250, totalTime: 500, numberOfBlinks: 6}`
 *
export const badgeWritersDoBlink = (
  { makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  blink: Blink = defaultBlink,
  { cleanupFn, successFn }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (dispatch, getState) => {
    let badgeWritersDoBlinkResponse: FirebaseHttpsCallableResult | null = null;
    // default values
    const color = blink.color || defaultBlink.color;
    const onTime = blink.onTime || defaultBlink.onTime;
    const totalTime = blink.totalTime || defaultBlink.totalTime;
    const numberOfBlinks = blink.numberOfBlinks || defaultBlink.numberOfBlinks;
    const { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
    try {
      if (!clientId || !badgeWriterId) {
        throw new Error('badgeWriter not found');
      }
      badgeWritersDoBlinkResponse = await firebaseFunctionsHttpsCallable<
        // done
        {
          clientId: string;
          deviceId: string;
          color: { r: number; g: number; b: number };
          onTime: number;
          totalTime: number;
          numberOfBlinks: number;
        },
        any
      >(
        firebaseFunctions,
        'devicesDoBlink'
      )({
        clientId, // required
        deviceId: badgeWriterId,
        color, // optional
        onTime, // optional, the time the LED is on
        totalTime, // optional, the total blink cycle time (including onTime)
        numberOfBlinks, // optional, the number of blinks
      });
    } catch (error) {
      makeErrorToast({ message: 'Error while blinking device' });
      cleanupFn && cleanupFn();
    }
    if (badgeWritersDoBlinkResponse) {
      successFn && successFn();
      dispatch(badgeWriterIsBlinking(badgeWriterId, { color, onTime, totalTime, numberOfBlinks }));
    } else {
      cleanupFn && cleanupFn();
    }
  };
};
/**
 * Make `badgeWriterId` blink with `blink` configurations [NEW API]
 *
 * `blink` has a default value of `{rgb: '255;255;255', onTime: 250, totalTime: 500, numberOfBlinks: 6}`
 */
export const badgeWritersDoBlink = (
  { makeErrorToast, makeSuccessToast }: ToasterContextObj,
  badgeWriterId: string,
  blink: Blink = defaultBlink,
  { cleanupFn, successFn }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    let response: HttpResponse | null = null;
    // default values
    const color = blink.color || defaultBlink.color;
    const onTime = blink.onTime || defaultBlink.onTime;
    const totalTime = blink.totalTime || defaultBlink.totalTime;
    const numberOfBlinks = blink.numberOfBlinks || defaultBlink.numberOfBlinks;
    try {
      if (!badgeWriterId) {
        throw new Error('badgeWriter not found');
      }
      response = await api.post(`badgeWriters/${badgeWriterId}/blinkCommands`, {
        color, // optional
        onTime, // optional, the time the LED is on
        totalTime, // optional, the total blink cycle time (including onTime)
        numberOfBlinks, // optional, the number of blinks
      });
      if (response) {
        if (response.status >= 400) {
          makeErrorToast({ message: response.data.message || 'Error while sending blink request' });
          cleanupFn && cleanupFn();
        } else {
          successFn && successFn();
          makeSuccessToast({ message: response.data.message || 'Blink request sent' });
          dispatch(
            badgeWriterIsBlinking(badgeWriterId, { color, onTime, totalTime, numberOfBlinks })
          );
        }
      } else {
        cleanupFn && cleanupFn();
        makeErrorToast({ message: 'Error while blinking badgeWriter' });
      }
    } catch (error: any) {
      makeErrorToast({
        message: `Error while blinking badgeWriter ${error.message ? ': ' + error.message : ''}`,
      });
      cleanupFn && cleanupFn();
    }
  };
};
/**
 *  Make redux store reflect blinking state of `badgeWriterId` with `blink` configurations
 */
export const badgeWriterIsBlinking = (
  badgeWriterId: string,
  blink: Blink
): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    dispatch(_setBlink(blink, badgeWriterId));
    setTimeout(() => {
      dispatch(_setBlink(null, badgeWriterId));
    }, blink.totalTime * blink.numberOfBlinks);
  };
};
/**
 * Modify wake up interval
 *
export const badgeWritersModifyWUInterval = (
  { makeErrorToast, makeSuccessToast }: ToasterContextObj,
  badgeWriterId: string,
  { numberOfAlarms, minimumTimeDifference }: WakeupInterval
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    let badgeWritersModifyWUIntervalResponse: FirebaseHttpsCallableResult<any> | null = null;
    try {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (!badgeWriterId || !clientId) {
        throw new Error('badgeWriter not found');
      }
      badgeWritersModifyWUIntervalResponse = await firebaseFunctionsHttpsCallable<
        WakeupInterval & { clientId: string; deviceId: string },
        any
      >(
        firebaseFunctions,
        'devicesSetWakeupInterval'
      )({
        clientId,
        deviceId: badgeWriterId,
        numberOfAlarms,
        minimumTimeDifference,
      });
    } catch (error) {
      makeErrorToast({ message: 'Error while updating interval' });
    }
    if (badgeWritersModifyWUIntervalResponse) {
      const data = badgeWritersModifyWUIntervalResponse.data;
      if (data?.error) {
        makeErrorToast({ message: data?.message || 'Error while updating interval' });
      } else {
        makeSuccessToast({ message: 'Updated successfully.' });
      }
    }
  };
};
/**
 * reserve a badgeWriter
 *
export const badgeWritersReserve = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user) {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (clientId) {
        firebaseFunctionsHttpsCallable<
          // done
          { clientId: string; deviceId: string; userId: string; userName: string },
          any
        >(
          firebaseFunctions,
          'devicesReserve'
        )({
          clientId,
          deviceId: badgeWriterId,
          userId: user.userId,
          userName: user.displayName || user.userId,
        })
          .then(response => {
            const data = response.data;
            if (data === true) {
              makeSuccessToast({ message: 'BadgeWriter reserved successfully' });
              successFn();
            } else if (data?.error) {
              makeErrorToast({ message: 'Error while reserving badgeWriter: ' + data.message });
              cleanupFn();
            } else {
              makeErrorToast({ message: 'Error while reserving badgeWriter. Try again' });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: 'Error while reserving badgeWriter: ' + error.message || error,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while reserving badgeWriter: badgeWriter not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while reserving badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * reserve a badgeWriter [NEW API]
 */
export const badgeWritersReserve = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user) {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (clientId) {
        api
          .post(`/badgeWriters/${badgeWriterId}/reservations`)
          .then(response => {
            if (response.status < 400) {
              makeSuccessToast({
                message: response.data.message || 'BadgeWriter reserved successfully',
              });
              successFn();
            } else {
              makeErrorToast({
                message: 'Error while reserving badgeWriter: ' + response.data.message,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: 'Error while reserving badgeWriter: ' + error.message || error,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while reserving badgeWriter: badgeWriter not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while reserving badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * release a badgeWriter
 *
export const badgeWritersRelease = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user) {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (clientId) {
        firebaseFunctionsHttpsCallable<
          // done
          { clientId: string; deviceId: string; userId?: string },
          any
        >(
          firebaseFunctions,
          'devicesRelease'
        )({
          clientId,
          deviceId: badgeWriterId,
          userId: user.isSuperUser ? undefined : user.userId,
        })
          .then(response => {
            const data = response.data;
            if (data === true) {
              makeSuccessToast({ message: 'BadgeWriter released successfully' });
              successFn();
            } else if (data?.error) {
              makeErrorToast({ message: 'Error while releasing badgeWriter: ' + data.message });
              cleanupFn();
            } else {
              makeErrorToast({ message: 'Error while releasing badgeWriter. Try again' });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: 'Error while releasing badgeWriter: ' + error.message || error,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while releasing badgeWriter: badgeWriter not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while releasing badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * release a badgeWriter [NEW API]
 */
export const badgeWritersRelease = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user) {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (clientId) {
        api
          .delete(`/badgeWriters/${badgeWriterId}/reservations`)
          .then(response => {
            if (response.status < 400) {
              makeSuccessToast({
                message: response.data.message || 'BadgeWriter released successfully',
              });
              successFn();
            } else {
              makeErrorToast({
                message: 'Error while releasing badgeWriter: ' + response.data.message,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: 'Error while releasing badgeWriter: ' + error.message || error,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while releasing badgeWriter: badgeWriter not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while releasing badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * toggling enabled property in badgeWriter
 * @param badgeWriterId
 * @returns promise
 *
export const badgeWritersToggleEnabledDevice = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const isSuperUser = getState().authentication.authenticationUser?.isSuperUser || false;
    let { clientId, enabled } = getState().badgeWriters.badgeWriters[badgeWriterId];
    if (clientId) {
      if (isSuperUser) {
        firebaseFunctionsHttpsCallable<
          // done
          {
            clientId: string;
            deviceId: string;
          },
          any
        >(
          firebaseFunctions,
          'devicesToggleEnabled'
        )({ clientId, deviceId: badgeWriterId })
          .then(result => {
            const data = result.data;
            if (data === true) {
              makeSuccessToast({
                message: `BadgeWriter ${badgeWriterId} is now ${enabled ? 'disabled' : 'enabled'}`,
              });
              successFn();
            } else if (data?.error) {
              makeErrorToast({
                message: `Error while ${enabled ? 'disabling' : 'enabling'}  badgeWriter: ${
                  data.message
                }`,
              });
              cleanupFn();
            } else {
              makeErrorToast({
                message: `Error while ${enabled ? 'disabling' : 'enabling'} badgeWriter. Try again`,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: `Error while ${enabled ? 'disabling' : 'enabling'} badgeWriter: ${
                error.message || error
              }`,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Only super users can enable or disable badgeWriters' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'BadgeWriter not found' });
      cleanupFn();
    }
  };
};
/**
 * toggling enabled property in badgeWriter [NEW API]
 * @param badgeWriterId
 * @returns promise
 */
export const badgeWritersToggleEnabledDevice = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    let { clientId, enabled } = getState().badgeWriters.badgeWriters[badgeWriterId];
    if (clientId) {
      api
        .patch(`/badgeWriters/${badgeWriterId}/enabledStatuses`, {
          enabled: !enabled,
        })
        .then(response => {
          if (response.status < 400) {
            makeSuccessToast({
              message:
                response.data.message ||
                `BadgeWriter ${badgeWriterId} is now ${enabled ? 'disabled' : 'enabled'}`,
            });
            successFn();
          } else {
            makeErrorToast({
              message: `Error while ${enabled ? 'disabling' : 'enabling'}  badgeWriter: ${
                response.data.message
              }`,
            });
            cleanupFn();
          }
        })
        .catch(error => {
          makeErrorToast({
            message: `Error while ${enabled ? 'disabling' : 'enabling'} badgeWriter: ${
              error.message || error
            }`,
          });
          cleanupFn();
        });
    } else {
      makeErrorToast({ message: 'BadgeWriter not found' });
      cleanupFn();
    }
  };
};
/**
 * update badgeWriter data
 *
export const badgeWritersUpdate = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  { deviceId, label, canBlink }: DeviceFormData,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user?.userId) {
      const badgeWriter = getState().badgeWriters.badgeWriters[deviceId];
      if (badgeWriter && badgeWriter.clientId) {
        if (user.isSuperUser) {
          firebaseFunctionsHttpsCallable<DeviceUpdateForm, any>( // done
            firebaseFunctions,
            'devicesUpdate'
          )({ clientId: deviceId, label, canBlink })
            .then(result => {
              const data = result.data;
              if (data === true) {
                makeSuccessToast({
                  message: `BadgeWriter ${deviceId} updated`,
                });
                successFn();
              } else if (data?.error) {
                makeErrorToast({
                  message: `Error while updating badgeWriter: ${data.message}`,
                });
                cleanupFn();
              } else {
                makeErrorToast({
                  message: `Error while updating badgeWriter. Try again`,
                });
                cleanupFn();
              }
            })
            .catch(error => {
              makeErrorToast({
                message: `Error while updating badgeWriter: ${error.message || error}`,
              });
              cleanupFn();
            });
        } else {
          makeErrorToast({ message: 'Error while updating badgeWriter: unauthorized' });
          cleanupFn();
        }
      } else {
        makeErrorToast({ message: 'Error while updating badgeWriter: badgeWriter not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while updating badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * update badgeWriter data [NEW API]
 */
export const badgeWritersUpdate = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  deviceId: string,
  { label }: BadgeWriterUpdatable,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user?.userId) {
      const badgeWriter = getState().badgeWriters.badgeWriters[deviceId];
      if (badgeWriter && badgeWriter.clientId) {
        api
          .patch(`/badgeWriters/${deviceId}`, { data: { label } })
          .then(response => {
            if (response.status < 400) {
              makeSuccessToast({
                message: response.data.message || `BadgeWriter ${deviceId} updated`,
              });
              successFn();
            } else {
              makeErrorToast({
                message: `Error while updating badgeWriter: ${response.data.message}`,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: `Error while updating badgeWriter: ${error.message || error}`,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while updating badgeWriter: badgeWriter not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while updating badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
export const badgeWritersCanBlinkUpdate = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  deviceId: string,
  canBlink: boolean,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user?.userId) {
      const badgeWriter = getState().badgeWriters.badgeWriters[deviceId];
      if (badgeWriter && badgeWriter.clientId) {
        api
          .patch(`/badgeWriters/${deviceId}/canBlinkStatuses`, { canBlink })
          .then(response => {
            if (response.status < 400) {
              makeSuccessToast({
                message: response.data.message || `BadgeWriter ${deviceId} updated`,
              });
              successFn();
            } else {
              makeErrorToast({
                message: `Error while updating badgeWriter: ${response.data.message}`,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: `Error while updating badgeWriter: ${error.message || error}`,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while updating badgeWriter: badgeWriter not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while updating badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * create new badgeWriter
 *
export const badgeWritersCreate = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  { label, canBlink }: BadgeWriterCreateData,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user?.userId) {
      if (user.isSuperUser) {
        firebaseFunctionsHttpsCallable<BadgeWriterCreateData, any>( // done
          firebaseFunctions,
          'devicesCreate'
        )({ label, canBlink })
          .then(result => {
            const data = result.data;
            if (data === true) {
              makeSuccessToast({
                message: `BadgeWriter created`,
              });
              successFn();
            } else if (data?.error) {
              makeErrorToast({
                message: `Error while creating badgeWriter: ${data.message}`,
              });
              cleanupFn();
            } else {
              makeErrorToast({
                message: `Error while creating badgeWriter. Try again`,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: `Error while creating badgeWriter: ${error.message || error}`,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while creating badgeWriter: unauthorized' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while creating badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * create new badgeWriter [NEW API]
 */
export const badgeWritersCreate = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  { label }: BadgeWriterCreateData,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user?.userId) {
      if (user.isSuperUser) {
        // BadgeWriters -> Create
        api
          .post(`/badgeWriters/`, { label })
          .then(response => {
            if (response.status < 400) {
              makeSuccessToast({
                message: response.data.message || `BadgeWriter created`,
              });
              successFn();
            } else {
              makeErrorToast({
                message: `Error while creating badgeWriter: ${response.data.message}`,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: `Error while creating badgeWriter: ${error.message || error}`,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while creating badgeWriter: unauthorized' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while creating badgeWriter: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * read a badge through a badgeWriter
 *
export const badgeWritersDoRead = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user) {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (clientId) {
        firebaseFunctionsHttpsCallable<
          // done
          { clientId: string; deviceId: string; userId: string; userName: string },
          any
        >(
          firebaseFunctions,
          'badgeWritersDoRead'
        )({
          clientId,
          deviceId: badgeWriterId,
          userId: user.userId,
          userName: user.displayName || user.userId,
        })
          .then(response => {
            const data = response.data;
            if (data === true) {
              makeSuccessToast({ message: 'Read request sent.' });
              successFn();
            } else if (data?.error) {
              makeErrorToast({ message: 'Error while sending read request: ' + data.message });
              cleanupFn();
            } else {
              makeErrorToast({ message: 'Error while sending read request. Try again' });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: 'Error while sending read request: ' + error.message || error,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while sending read request: badge writer not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while sending read request: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * read a badge through a badgeWriter [NEW API]
 */
export const badgeWritersDoRead = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user) {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (clientId) {
        api
          .post(`/badgeWriters/${badgeWriterId}/readCommands`)
          .then(response => {
            if (response.status < 400) {
              makeSuccessToast({ message: 'Read request sent.' });
              successFn();
            } else {
              makeErrorToast({
                message: 'Error while sending read request: ' + response.data.message,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: 'Error while sending read request: ' + error.message || error,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while sending read request: badge writer not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while sending read request: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * write a badge through a badgeWriter
 *
export const badgeWritersDoWrite = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user) {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (clientId) {
        firebaseFunctionsHttpsCallable<
          // done
          { clientId: string; deviceId: string; userId: string; userName: string },
          any
        >(
          firebaseFunctions,
          'badgeWritersDoWrite'
        )({
          clientId,
          deviceId: badgeWriterId,
          userId: user.userId,
          userName: user.displayName || user.userId,
        })
          .then(response => {
            const data = response.data;
            if (data === true) {
              makeSuccessToast({ message: 'Write request sent.' });
              successFn();
            } else if (data?.error) {
              makeErrorToast({ message: 'Error while sending write request: ' + data.message });
              cleanupFn();
            } else {
              makeErrorToast({ message: 'Error while sending write request. Try again' });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: 'Error while sending write request: ' + error.message || error,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while sending write request: badge writer not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while sending write request: authentication null' });
      cleanupFn();
    }
  };
};
/**
 * write a badge through a badgeWriter [NEW API]
 */
export const badgeWritersDoWrite = (
  { makeSuccessToast, makeErrorToast }: ToasterContextObj,
  badgeWriterId: string,
  { cleanupFn = () => {}, successFn = () => {} }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const user = getState().authentication.authenticationUser;
    if (user) {
      let { clientId } = getState().badgeWriters.badgeWriters[badgeWriterId];
      if (clientId) {
        api
          .post(`/badgeWriters/${badgeWriterId}/writeCommands`)
          .then(response => {
            if (response.status < 400) {
              makeSuccessToast({ message: 'Write request sent.' });
              successFn();
            } else {
              makeErrorToast({
                message: 'Error while sending write request: ' + response.data.message,
              });
              cleanupFn();
            }
          })
          .catch(error => {
            makeErrorToast({
              message: 'Error while sending write request: ' + error.message || error,
            });
            cleanupFn();
          });
      } else {
        makeErrorToast({ message: 'Error while sending write request: badge writer not found' });
        cleanupFn();
      }
    } else {
      makeErrorToast({ message: 'Error while sending write request: authentication null' });
      cleanupFn();
    }
  };
};
/**
 *
 *
export const updateDevices = (devices: Device[]): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const authenticationUser = getState().authentication.authenticationUser;
    const deviceId = authenticationUser?.deviceId || null;
    if (deviceId) {
      const isSuperDevice = authenticationUser?.isSuperDevice === true;
      if (isSuperDevice) {
        try {
          await firebaseFunctionsHttpsCallable(
            firebaseFunctions,
            'updateSystemDevices'
          )({
            deviceId,
            devices,
          });
        } catch (error) {
          // @error
        }
      }
    }
    return;
  };
};
/* */
const _unsetBadgeWriter = (badgeWriterId: string) => {
  const millis = Date.now();
  return badgeWritersActions.unsetBadgeWriter({
    badgeWriterId,
    millis,
  });
};
const _unsetBadgeWriters = () => {
  const millis = Date.now();
  return badgeWritersActions.badgeWritersUnset({
    millis,
  });
};
const _mergeBadgeWriters = (badgeWriters: BadgeWriters) => {
  const millis = Date.now();
  return badgeWritersActions.badgeWritersMerge({
    badgeWriters,
    millis,
  });
};
const _setBlink = (blinkProperties: Blink | null, badgeWriterId: string) => {
  return badgeWritersActions.badgeWriterSetBlink({
    blinkProperties,
    badgeWriterId,
  });
};
