/*
 * imports
 */
import { configureStore } from '@reduxjs/toolkit';
// reducers
import authenticationReducer from './authentication/slice';
import badgeWritersReducer from './badgeWriters/slice';
import rolesReducer from './roles/slice';
import securityReducer from './security/slice';
import systemReducer from './system/slice';
import taskSetupReducer from './taskSetup/slice';
import usersReducer from './users/slice';
/*
 *
 *
import logger from '@infobiotech/js-logger';
const componentName = 'ReduxStore';
logger.setLogLevel(logger.INFO, componentName);
/*
 *
 */
const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    badgeWriters: badgeWritersReducer,
    roles: rolesReducer,
    security: securityReducer,
    system: systemReducer,
    taskSetup: taskSetupReducer,
    users: usersReducer,
  },
});
/*
 * exports
 */
export default store;
