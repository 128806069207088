/*
 * imports
 */
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getFirestore,
  collection as firestoreCollection,
  addDoc as firestoreAddDoc,
  getDocs as firestoreGetDocs,
  doc as firestoreDoc,
  getDoc as firestoreGetDoc,
  onSnapshot as firestoreOnSnapshot,
  connectFirestoreEmulator,
  query as firestoreQuery,
  limit as firestoreLimit,
  where as firestoreWhere,
  orderBy as firestoreOrderBy,
} from 'firebase/firestore';
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable as firebaseFunctionsHttpsCallable,
} from 'firebase/functions';
import {
  getAuth,
  connectAuthEmulator,
  createUserWithEmailAndPassword as firebaseAuthCreateUserWithEmailAndPassword,
  checkActionCode as firebaseCheckActionCode,
  isSignInWithEmailLink as firebaseAuthIsSignInWithEmailLink,
  signInWithEmailLink as firebaseAuthSignInWithEmailLink,
  onAuthStateChanged as firebaseAuthOnAuthStateChanged,
  sendSignInLinkToEmail as firebaseAuthSendSignInLinkToEmail,
  signInWithEmailAndPassword as firebaseAuthSignInWithEmailAndPassword,
  signInWithPopup as firebaseAuthSignInWithPopup,
  signOut as firebaseAuthSignOut,
  updateProfile as firebaseAuthUpdateProfile,
  updatePassword,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  EmailAuthProvider,
  GithubAuthProvider,
  sendEmailVerification,
  updateEmail,
  // deleteUser,
  linkWithPopup,
  UserInfo,
  reauthenticateWithPopup,
  linkWithCredential,
  unlink,
  signInAnonymously,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
// import firebaseFunctions from 'firebase-functions';
// import firebaseAdmin from 'firebase-admin';
// firebaseAdmin.initializeApp();
/*
 * types
 */
import type { AuthenticateArgs } from './types';
/*
 * config variables
 */
import configModule from '../../config.module';
import { api, setAuth } from '../api';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseProductionConfig = {
  /*
   * saas-console
   *
  apiKey: 'AIzaSyBlS7HcL1gIlRCzhjyhMJjqTAvuv8GnyUs',
  authDomain: 'saas-7a3df.firebaseapp.com',
  projectId: 'saas-7a3df',
  storageBucket: 'saas-7a3df.appspot.com',
  messagingSenderId: '1093635751268',
  appId: '1:1093635751268:web:94518886f0ef3958ed0e71',
  measurementId: 'G-NBTCHSVFX2',
  /*
   * enobit
   *
  apiKey: 'AIzaSyDRr_DM1vReG1t1HWpOj6KXdYqtlSlQz00',
  authDomain: 'enobit2020.firebaseapp.com',
  databaseURL: 'https://enobit2020-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'enobit2020',
  storageBucket: 'enobit2020.appspot.com',
  messagingSenderId: '881209229788',
  appId: '1:881209229788:web:455e5c4c0d97b495914f19',
  measurementId: 'G-3T8BZRFMYX',
  /*
   * icgene4
   */
  apiKey: 'AIzaSyDhDJs_qj_2FcHaDFMmCp3FOKPFUrLDYw8',
  authDomain: 'icgene4.firebaseapp.com',
  projectId: 'icgene4',
  storageBucket: 'icgene4.appspot.com',
  messagingSenderId: '282827944281',
  appId: '1:282827944281:web:3919a8ab2a8aae82c2dc48',
  measurementId: 'G-DCZ47S9TK2',
  /* */
};
/*
 *
 *
import logger from '@infobiotech/js-logger';
const componentName = 'Firebase';
logger.setLogLevel(logger.INFO, componentName);
logger.info(componentName);
/*
 * init
 */
const firebaseApp = initializeApp(firebaseProductionConfig);
/* const analytics = */ getAnalytics(firebaseApp);
//
const firebaseAuth = getAuth(firebaseApp);
setAuth({ firebaseAuth });
// firebaseAuth.languageCode = 'it';
// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();
if (window.location.hostname === 'localhost' && configModule.firebase.emulators.auth) {
  connectAuthEmulator(firebaseAuth, 'http://localhost:9099', { disableWarnings: true });
}
// firestore instance
const firestore = getFirestore(firebaseApp);
if (window.location.hostname === 'localhost' && configModule.firebase.emulators.firestore) {
  connectFirestoreEmulator(firestore, 'localhost', 8080);
}
// firebase cloud functions
const firebaseFunctions = getFunctions(firebaseApp, configModule.firebase.functions.region);
firebaseApp;
if (window.location.hostname === 'localhost' && configModule.firebase.emulators.functions) {
  connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
}
/*
 * functions
 */
const hasProvider = (providers: UserInfo[], providerId: string) => {
  for (const provider of providers) {
    if (providerId === provider.providerId) {
      return true;
    }
  }
  return false;
};
const firebaseReauthenticate = async ({ providerId, email, password }: AuthenticateArgs) => {
  const user = firebaseAuth.currentUser;
  if (user) {
    const providers = user.providerData.filter(provider => provider.providerId !== 'password');
    if (providerId) {
      if (hasProvider(providers, providerId)) {
        // providers
        if (providerId === GoogleAuthProvider.PROVIDER_ID) {
          const googleAuth = new GoogleAuthProvider();
          return reauthenticateWithPopup(user, googleAuth);
        } else if (providerId === GithubAuthProvider.PROVIDER_ID) {
          const githubAuth = new GithubAuthProvider();
          return reauthenticateWithPopup(user, githubAuth);
        } else {
          // TODO: ELSE do more providers: Facebook, Twitter and so on?
          throw new Error('No provider found. There are no ways to authenticate');
        }
      } else {
        throw new Error('This account does not have this provider linked');
      }
    } else if (email) {
      if (password) {
        const credential = EmailAuthProvider.credential(email, password);
        return reauthenticateWithCredential(user, credential);
      } else {
        // email-only authentication it is not enabled
        throw new Error('Please provide a password');
      }
    } else if (providers.length > 0) {
      // TODO: try random providers. For now an error occurs
      throw new Error('No provider found. There are no ways to authenticate');
    } else {
      throw new Error('No provider selected. There are no ways to authenticate');
    }
  } else {
    // error user not exists
    throw new Error('User does not exists. Try again or try loggin out and logging in again.');
  }
};
/**
 * update password through firebase sdk
 */
const firebaseUpdatePassword = (newPassword: string) => {
  const user = firebaseAuth.currentUser;
  if (user) {
    return updatePassword(user, newPassword);
  }
  throw new Error('User instance is null');
};
/**
 * update email through firebase sdk
 */
const firebaseUpdateEmail = async (newEmail: string, userId?: string) => {
  const user = firebaseAuth.currentUser;
  if (user && userId) {
    await updateEmail(user, newEmail);
    return await firestoreUpdateEmail(newEmail, userId);
  }
  throw new Error('User not authenticated. Please log in again.');
};
/**
 * Update a user's email
 */
const firestoreUpdateEmail = (newEmail: string | null, userId?: string) => {
  if (!userId) {
    throw new Error('User not authenticated. Please log in again.');
  } else if (!newEmail) {
    throw new Error('Email not valid.');
  } else {
    return firebaseFunctionsHttpsCallable<{
      userId: string;
      emailAddress: string;
      emailVerified: boolean;
    }>(
      firebaseFunctions,
      'userUpdate'
    )({ userId, emailAddress: newEmail, emailVerified: false });
  }
};
/**
 * [NEW API]
 * @param newEmail
 * @param userId
 * @returns
 */
const firestoreUpdateEmailAPI = (newEmail: string | null, userId?: string) => {
  if (!userId) {
    throw new Error('User not authenticated. Please log in again.');
  } else if (!newEmail) {
    throw new Error('Email not valid.');
  } else {
    return api.patch(`/users/${userId}/emailAddresses`, {
      userId,
      emailAddress: newEmail,
      emailVerified: false,
    });
  }
};
/**
 *
 * firebase delete user function
 *
const firebaseDeleteUser = () => {
  const user = firebaseAuth.currentUser;
  if (user) {
    return deleteUser(user);
  }
  throw new Error('User instance is null');
};
/**
 * send verification email link when an email has not been verified yet
 */
const firebaseSendEmailVerification = () => {
  const user = firebaseAuth.currentUser;
  if (user) {
    return sendEmailVerification(user, {
      url: `${configModule.baseUrl}/user/email-verified`,
    });
  }
  throw new Error('User instance is null');
};
/**
 * link a federated auth provider to an user account
 */
const firebaseLinkAccount = ({ providerId, email, password }: AuthenticateArgs) => {
  const user = firebaseAuth.currentUser;
  if (user) {
    const providers = user.providerData;
    if (providerId) {
      if (!hasProvider(providers, providerId)) {
        // providers
        if (providerId === GoogleAuthProvider.PROVIDER_ID) {
          const googleAuth = new GoogleAuthProvider();
          return linkWithPopup(user, googleAuth);
        } else if (providerId === GithubAuthProvider.PROVIDER_ID) {
          const githubAuth = new GithubAuthProvider();
          return linkWithPopup(user, githubAuth);
        } else {
          // TODO: ELSE do more providers: Facebook, Twitter and so on?
          throw new Error('Unknown provider. Please try another method.');
        }
      } else {
        throw new Error('This account does already have this provider linked');
      }
    } else if (email) {
      if (password) {
        const credential = EmailAuthProvider.credential(email, password);
        return linkWithCredential(user, credential);
      } else {
        // email-only authentication it is not enabled
        throw new Error('Please provide a password');
      }
    } else if (providers.length > 0 && user.email) {
      fetchSignInMethodsForEmail(firebaseAuth, user.email).then(providers => {
        console.log('try random providers: TODO.', { providers });
      });
      // TODO: try random providers. For now an error occurs
      throw new Error('Not found any provider to link.');
    } else {
      throw new Error('No provider selected. Cannot link any account.');
    }
  } else {
    // error user not exists
    throw new Error('User does not exists. Try again or try loggin out and logging in again.');
  }
};
const firebaseUnlinkAccount = (providerId: string) => {
  const user = firebaseAuth.currentUser;
  if (user) {
    const providers = user.providerData;
    if (hasProvider(providers, providerId)) {
      return unlink(user, providerId);
    } else {
      throw new Error('This account does not have such provider linked.');
    }
  } else {
    // error user not exists
    throw new Error('User does not exists. Try again or try loggin out and logging in again.');
  }
};
const firebaseAuthSignInAnonymously = () => {
  return signInAnonymously(firebaseAuth);
};
/*
 * exports
 */
export {
  firebaseApp,
  // functions
  firebaseFunctions,
  firebaseFunctionsHttpsCallable,
  // firebase auth
  firebaseAuth,
  firebaseAuthCreateUserWithEmailAndPassword,
  firebaseCheckActionCode,
  firebaseAuthIsSignInWithEmailLink,
  firebaseAuthSignInWithEmailLink,
  firebaseAuthOnAuthStateChanged,
  firebaseAuthSendSignInLinkToEmail,
  firebaseAuthSignInWithEmailAndPassword,
  firebaseAuthSignInWithPopup,
  firebaseAuthSignOut,
  firebaseAuthUpdateProfile,
  firebaseAuthSignInAnonymously,
  // utilities
  firebaseUpdatePassword,
  firebaseUpdateEmail,
  firebaseReauthenticate,
  // firebaseDeleteUser,
  firebaseSendEmailVerification,
  firebaseLinkAccount,
  firebaseUnlinkAccount,
  // providers
  GoogleAuthProvider,
  // firestore
  firestore,
  firestoreCollection,
  firestoreAddDoc,
  firestoreGetDocs,
  firestoreGetDoc,
  firestoreDoc,
  firestoreOnSnapshot,
  firestoreQuery,
  firestoreLimit,
  firestoreWhere,
  firestoreOrderBy,
  firestoreUpdateEmail,
  firestoreUpdateEmailAPI,
};
