/*
 * imports
 */
// redux imports
// page import
import HomePage from '../../UI/Pages/HomePage';
// types
import type { FCWithChildren } from '../../types';
/* *
import logger from '@infobiotech/js-logger';
const componentName = 'HomeController';
logger.setLogLevel(logger.INFO, componentName);
/* */
/**
 * Home Page Controller
 */
const HomeController: FCWithChildren = () => {
  /*
   * Redux store
   *
  /* *
  logger.info(componentName, 'render');
  /* */
  return <HomePage />;
};
/*
 * export
 */
export default HomeController;
