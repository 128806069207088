/*
 * imports
 */
import './styles.css';
// types
import type { FCWithChildren } from '../../../types';
import type { IBTLoadingSpinnerProps } from './types';
/**
 * Component
 */
const IBTLoadingSpinner: FCWithChildren<IBTLoadingSpinnerProps> = props => {
  /*
   * local state
   */
  /*
   * useEffects
   */
  /*
   * render
   */
  return (
    <div className={`ibt-loader ${props.centered ? 'centered' : ''}`}>
      <div className={`ibt-loader-points ${props.noAnimate ? 'no-animate' : ''}`}>
        <div className="ibt-loader-point first"></div>
        <div className="ibt-loader-point second"></div>
        <div className="ibt-loader-point third"></div>
        <div className="ibt-loader-point fourth"></div>
      </div>
      {props.message && <div className="ibt-load-txt bp3-text-large">{props.message}</div>}
    </div>
  );
};
/*
 * export
 */
export default IBTLoadingSpinner;
