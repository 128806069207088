/*
 * imports
 */
import { createSlice } from '@reduxjs/toolkit';
// external libraries
// types
import type { Payload } from '../store.types';
import type { RoleName, RolesState } from './types';
/**
 * initial state
 */
const rolesInitialState: RolesState = {};
/**
 * roles slice
 */
const rolesSlice = createSlice({
  name: 'roles',
  initialState: rolesInitialState,
  reducers: {
    setRoles: (state: RolesState, action: Payload<{ roles: RolesState }>) => {
      const { roles } = action.payload;
      return roles;
    },
    deleteRole: (state: RolesState, action: Payload<{ roleName: RoleName }>) => {
      const { roleName } = action.payload;
      delete state[roleName];
      return state;
    },
  },
});
/*
 * exports
 */
export default rolesSlice.reducer;
export const rolesActions = rolesSlice.actions;
/** */
