/*
 * imports
 */
// pages
import SignedInPage from '../../Components/SignedInPage';
// types
import type { FCWithChildren } from '../../../types';
/**
 * Unauthorized Page
 */
const UnauthorizedPage: FCWithChildren = () => {
  /*
   * local state
   */
  const layout = {
    withPadding: true,
    centered: true,
  };
  /*
   * render
   */
  return <SignedInPage layout={layout}>Unauthorized</SignedInPage>;
};
/*
 * export
 */
export default UnauthorizedPage;
