/*
 * imports
 */
import './styles.css';
import { useState } from 'react';
// router imports
import { useNavigate } from 'react-router-dom';
// redux imports
// external imports
import { Trans } from 'react-i18next';
import { Alert, Classes, Drawer, DrawerSize, Menu, MenuItem } from '@blueprintjs/core';
import { t } from 'i18next';
// types
import type { FCWithChildren } from '../../../../types';
import type { UserDrawerProps } from './types';
/**
 * Component
 */
const UserDrawer: FCWithChildren<UserDrawerProps> = props => {
  /*
   * redux
   */
  /*
   * local state
   */
  const navigate = useNavigate();
  const [signOutConfirmOpened, setSignOutConfirmOpened] = useState<boolean>(false);
  /*
   * useEffects
   */
  /*
   * handlers
   */
  const signOutConfirmOpenedHandler = () => {
    setSignOutConfirmOpened(true);
  };
  /**
   * cancel sign out
   */
  const alertCancelHandler = () => {
    setSignOutConfirmOpened(false);
  };
  /**
   * sign out confirmed
   */
  const alertConfirmHandler = () => {
    setSignOutConfirmOpened(false);
    navigate('/signOut');
  };
  const userInfoClickHandler = () => {
    if (props.controller !== 'user') {
      navigate('/user');
    }
  };
  const settingsClickHandler = () => {
    if (props.controller !== 'settings') {
      navigate('/settings');
    }
  };
  /*
   * render
   */
  return (
    <Drawer
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      // className={'bp3-dark'}
      enforceFocus={true}
      hasBackdrop={true}
      icon="user"
      title="User account and preferences"
      isCloseButtonShown={true}
      isOpen={true}
      onClose={props.onClose}
      // onOpened={drawerOpenedHandler}
      position={'right'}
      usePortal={false}
      size={DrawerSize.SMALL}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          {!props.isAnonymous && (
            <Menu className="p-0">
              <MenuItem
                icon="user"
                text="Personal Informations"
                active={props.controller === 'user'}
                onClick={userInfoClickHandler}
              />
              <MenuItem
                icon="settings"
                text="Settings"
                active={props.controller === 'settings'}
                onClick={settingsClickHandler}
              />
            </Menu>
          )}
          {/*language === 'it' ? (
        <button type="button" className="engine-flag-container" onClick={() => changeLanguage('en')} >EN</button>
      ) : (
        <button type="button" className="engine-flag-container" onClick={() => changeLanguage('it')} >IT</button>
      )*/}
        </div>
      </div>
      <div className={`${Classes.DRAWER_FOOTER} p-1`}>
        <Menu className="p-0">
          <MenuItem
            icon="log-out"
            text="Sign-out"
            active={false}
            intent={'danger'}
            onClick={signOutConfirmOpenedHandler}
          />
          <Alert
            className={'bp3-light'}
            cancelButtonText={`${t('labels.cancel')}`}
            confirmButtonText={`${t('signOut.label')}`}
            icon="log-out"
            intent={'danger'}
            isOpen={signOutConfirmOpened}
            loading={false}
            onCancel={alertCancelHandler}
            // confirmHandler
            onConfirm={alertConfirmHandler}
          >
            <p>
              <Trans i18nKey="signOut.confirmMessage" />
            </p>
          </Alert>
        </Menu>
      </div>
    </Drawer>
  );
};
/*
 * export
 */
export default UserDrawer;
