import { FirebaseError } from 'firebase/app';
/*
 * imports
 */
/*
 * main
 */
/**
 * Admin Authentication API Errors thrown by the Firebase Admin Node.js Authentication API
 */
const getAdminAuthenticationErrorMessage = (error: FirebaseError) => {
  /** *
    logger.error(componentName, 'authenticationSignOut', 'firebaseAuth.signOut.catch', {
      error,
    });
  /** */
  if (error.code === 'auth/claims-too-large') {
    // The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes.
  } else if (error.code === 'auth/email-already-exists') {
    // The provided email is already in use by an existing user. Each user must have a unique email.
  } else if (error.code === 'auth/id-token-expired') {
    // The provided Firebase ID token is expired.
  } else if (error.code === 'auth/id-token-revoked') {
    // The Firebase ID token has been revoked.
  } else if (error.code === 'auth/insufficient-permission') {
    // The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource.
    // Refer to Set up a Firebase project for documentation on how to generate a credential
    // with appropriate permissions and use it to authenticate the Admin SDKs.
  } else if (error.code === 'auth/internal-error') {
    // The Authentication server encountered an unexpected error while trying to process the request.
    // The error message should contain the response from the Authentication server containing additional information.
    // If the error persists, please report the problem to our Bug Report support channel.
  } else if (error.code === 'auth/invalid-argument') {
    // An invalid argument was provided to an Authentication method. The error message should contain additional information.
  } else if (error.code === 'auth/invalid-claims') {
    // The custom claim attributes provided to setCustomUserClaims() are invalid.
  } else if (error.code === 'auth/invalid-continue-uri') {
    // The continue URL must be a valid URL string.
  } else if (error.code === 'auth/invalid-creation-time') {
    // The creation time must be a valid UTC date string.
  } else if (error.code === 'auth/invalid-credential') {
    // The credential used to authenticate the Admin SDKs cannot be used to perform the desired action.
    // Certain Authentication methods such as createCustomToken() and verifyIdToken() require the SDK to be
    // initialized with a certificate credential as opposed to a refresh token or Application Default credential.
    // See Initialize the SDK for documentation on how to authenticate the Admin SDKs with a certificate credential.
  } else if (error.code === 'auth/invalid-disabled-field') {
    // The provided value for the disabled user property is invalid. It must be a boolean.
  } else if (error.code === 'auth/invalid-display-name') {
    // The provided value for the displayName user property is invalid. It must be a non-empty string.
  } else if (error.code === 'auth/invalid-dynamic-link-domain') {
    // The provided dynamic link domain is not configured or authorized for the current project.
  } else if (error.code === 'auth/invalid-email') {
    // The provided value for the email user property is invalid. It must be a string email address.
  } else if (error.code === 'auth/invalid-email-verified') {
    // The provided value for the emailVerified user property is invalid. It must be a boolean.
  } else if (error.code === 'auth/invalid-hash-algorithm') {
    // The hash algorithm must match one of the strings in the list of supported algorithms.
  } else if (error.code === 'auth/invalid-hash-block-size') {
    // The hash block size must be a valid number.
  } else if (error.code === 'auth/invalid-hash-derived-key-length') {
    // The hash derived key length must be a valid number.
  } else if (error.code === 'auth/invalid-hash-key') {
    // The hash key must a valid byte buffer.
  } else if (error.code === 'auth/invalid-hash-memory-cost') {
    // The hash memory cost must be a valid number.
  } else if (error.code === 'auth/invalid-hash-parallelization') {
    // The hash parallelization must be a valid number.
  } else if (error.code === 'auth/invalid-hash-rounds') {
    // The hash rounds must be a valid number.
  } else if (error.code === 'auth/invalid-hash-salt-separator') {
    // The hashing algorithm salt separator field must be a valid byte buffer.
  } else if (error.code === 'auth/invalid-id-token') {
    // The provided ID token is not a valid Firebase ID token.
  } else if (error.code === 'auth/invalid-last-sign-in-time') {
    // The last sign-in time must be a valid UTC date string.
  } else if (error.code === 'auth/invalid-page-token') {
    // The provided next page token in listUsers() is invalid. It must be a valid non-empty string.
  } else if (error.code === 'auth/invalid-password') {
    // The provided value for the password user property is invalid. It must be a string with at least six characters.
  } else if (error.code === 'auth/invalid-password-hash') {
    // The password hash must be a valid byte buffer.
  } else if (error.code === 'auth/invalid-password-salt') {
    // The password salt must be a valid byte buffer
  } else if (error.code === 'auth/invalid-phone-number') {
    // The provided value for the phoneNumber is invalid. It must be a non-empty E.164 standard compliant identifier string.
  } else if (error.code === 'auth/invalid-photo-url') {
    // The provided value for the photoURL user property is invalid. It must be a string URL.
  } else if (error.code === 'auth/invalid-provider-data') {
    // The providerData must be a valid array of UserInfo objects.
  } else if (error.code === 'auth/invalid-provider-id') {
    // The providerId must be a valid supported provider identifier string.
  } else if (error.code === 'auth/invalid-oauth-responsetype') {
    // Only exactly one OAuth responseType should be set to true.
  } else if (error.code === 'auth/invalid-session-cookie-duration') {
    // The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.
  } else if (error.code === 'auth/invalid-uid') {
    // The provided uid must be a non-empty string with at most 128 characters.
  } else if (error.code === 'auth/invalid-user-import') {
    // The user record to import is invalid.
  } else if (error.code === 'auth/maximum-user-count-exceeded') {
    // The maximum allowed number of users to import has been exceeded.
  } else if (error.code === 'auth/missing-android-pkg-name') {
    // An Android Package Name must be provided if the Android App is required to be installed.
  } else if (error.code === 'auth/missing-continue-uri') {
    // A valid continue URL must be provided in the request.
  } else if (error.code === 'auth/missing-hash-algorithm') {
    // Importing users with password hashes requires that the hashing algorithm and its parameters be provided.
  } else if (error.code === 'auth/missing-ios-bundle-id') {
    // The request is missing a Bundle ID.
  } else if (error.code === 'auth/missing-uid') {
    // A uid identifier is required for the current operation.
  } else if (error.code === 'auth/missing-oauth-client-secret') {
    // The OAuth configuration client secret is required to enable OIDC code flow.
  } else if (error.code === 'auth/operation-not-allowed') {
    // The provided sign-in provider is disabled for your Firebase project.
    // Enable it from the Sign-in Method section of the Firebase console.
  } else if (error.code === 'auth/phone-number-already-exists') {
    // The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber.
  } else if (error.code === 'auth/project-not-found') {
    // No Firebase project was found for the credential used to initialize the Admin SDKs.
    // Refer to Set up a Firebase project for documentation on how to generate a credential
    // for your project and use it to authenticate the Admin SDKs.
  } else if (error.code === 'auth/reserved-claims') {
    // One or more custom user claims provided to setCustomUserClaims() are reserved.
    // For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.
  } else if (error.code === 'auth/session-cookie-expired') {
    // The provided Firebase session cookie is expired.
  } else if (error.code === 'auth/session-cookie-revoked') {
    // The Firebase session cookie has been revoked.
  } else if (error.code === 'auth/uid-already-exists') {
    // The provided uid is already in use by an existing user. Each user must have a unique uid.
  } else if (error.code === 'auth/unauthorized-continue-uri') {
    // The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase Console.
  } else if (error.code === 'auth/user-not-found') {
    // There is no existing user record corresponding to the provided identifier.
  } else if (error.code === 'auth/requires-recent-login') {
    // Thrown if the user's last sign-in time does not meet the security threshold.
    // Use firebase.User.reauthenticateWithCredential to resolve. This does not apply if the user is anonymous.
  }
  const errorCode = error.code || '111';
  const errorMessage = error.message || 'authentication sign out error';
  return `${errorCode} - ${errorMessage}`;
};
/**
 * Link account errors handler
 */
const linkAccountErrorHandler = (error: FirebaseError) => {
  // firebaseLinkAccount can throw general errors that do not have the code parameter
  if (error?.code) {
    if (error.code === 'auth/provider-already-linked') {
      // Thrown if the provider has already been linked to the user.
      // This error is thrown even if this is not the same provider's account that is currently linked to the user.
    } else if (error.code === 'auth/invalid-credential') {
      // Thrown if the provider's credential is not valid.
      // This can happen if it has already expired when calling link, or if it used invalid token(s).
      // See the Firebase documentation for your provider, and make sure you pass in the correct parameters to the credential method.
    } else if (error.code === 'auth/credential-already-in-use') {
      // Thrown if the account corresponding to the credential already exists among your users, or is already linked to a Firebase User.
      // For example, this error could be thrown if you are upgrading an anonymous user to a Google user
      // by linking a Google credential to it and the Google credential used is already associated with an existing Firebase Google user.
      // The fields error.email, error.phoneNumber, and error.credential (firebase.auth.AuthCredential)
      // may be provided, depending on the type of credential.
      // You can recover from this error by signing in with error.credential directly via firebase.auth.Auth.signInWithCredential.
    } else if (error.code === 'auth/email-already-in-use') {
      // Thrown if the email corresponding to the credential already exists among your users.
      // When thrown while linking a credential to an existing user, an error.email and error.credential
      // (firebase.auth.AuthCredential) fields are also provided.
      // You have to link the credential to the existing user with that email if you wish to continue signing in with that credential.
      // To do so, call firebase.auth.Auth.fetchSignInMethodsForEmail,
      // sign in to error.email via one of the providers returned and then
      // firebase.User.linkWithCredential the original credential to that newly signed in user.
    } else if (error.code === 'auth/operation-not-allowed') {
      // Thrown if you have not enabled the provider in the Firebase Console.
      // Go to the Firebase Console for your project, in the Auth section and the Sign in Method tab and configure the provider.
    } else if (error.code === 'auth/invalid-email') {
      // Thrown is email is not valid (fetchSignInMethods)
      // Thrown if the email used in a firebase.auth.EmailAuthProvider.credential is invalid.
    } else if (error.code === 'auth/wrong-password') {
      // Thrown if the password used in a firebase.auth.EmailAuthProvider.credential is not correct
      // or when the user associated with the email does not have a password.
    } else if (error.code === 'auth/invalid-verification-code') {
      // Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification code of the credential is not valid.
    } else if (error.code === 'auth/invalid-verification-id') {
      // Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification ID of the credential is not valid.
    } else if (error.code === 'auth/auth-domain-config-required') {
      // Thrown if authDomain configuration is not provided when calling firebase.initializeApp().
      // Check Firebase Console for instructions on determining and passing that field.
    } else if (error.code === 'auth/cancelled-popup-request') {
      // Thrown if successive popup operations are triggered.
      // Only one popup request is allowed at one time.
      // All the popups would fail with this error except for the last one.
    } else if (error.code === 'auth/operation-not-supported-in-this-environment') {
      // Thrown if this operation is not supported in the environment your application is running on.
      // "location.protocol" must be http or https.
    } else if (error.code === 'auth/popup-blocked') {
      // Thrown if the popup was blocked by the browser, typically when this operation is triggered outside of a click handler.
    } else if (error.code === 'auth/popup-closed-by-user') {
      // Thrown if the popup window is closed by the user without completing the sign in to the provider.
    } else if (error.code === 'auth/unauthorized-domain') {
      // Thrown if the app domain is not authorized for OAuth operations for your Firebase project.
      // Edit the list of authorized domains from the Firebase console.
    }
  }
  return error.message;
};
/*
 * exports
 */
export { getAdminAuthenticationErrorMessage, linkAccountErrorHandler };
