/*
 * imports
 */
import usePath from '../../Hooks/use-path';
import { Navigate } from 'react-router-dom';
// page imports
// redux imports
import { useSelector } from 'react-redux';
// types
import type { FCWithChildren } from '../../types';
import type { SignedInGuardProps } from './types';
import type { RootState } from '../../Store/Redux/store.types';
import type { User } from '../../Store/Redux/users/types';
/**
 * Component
 */
const SignedInGuard: FCWithChildren<SignedInGuardProps> = props => {
  /*
   * local state
   */
  const path = usePath().path;
  const authenticationUser = useSelector<RootState, User | null | undefined>(
    state => state.authentication.authenticationUser
  );
  /*
   * useEffects
   */
  /*
   * render
   */
  if (authenticationUser) {
    localStorage.removeItem('continuePath');
    return props.controller;
  }
  if (path !== '/signOut') {
    localStorage.setItem('continuePath', path);
  }
  return <Navigate to="/signIn" replace={true} />;
};
/*
 * export
 */
export default SignedInGuard;
