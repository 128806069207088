/*
 * imports
 */
import { createSlice } from '@reduxjs/toolkit';
// types
import type { Payload } from '../store.types';
import type { UsersState, Users } from './types';
/**
 * initial state
 */
const usersInitialState: UsersState = {
  users: {},
  usersMillis: 0,
  usersInvitingUser: false,
  usersDeletingUser: '',
};
/**
 * users slice
 */
const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    usersMerge(state: UsersState, action: Payload<{ users: Users; millis: number }>) {
      const { users, millis } = action.payload;
      state.users = { ...state.users, ...users };
      state.usersMillis = millis;
    },
    invitingUser(state: UsersState, action: Payload<{ invitingUser: boolean | null }>) {
      const { invitingUser } = action.payload;
      state.usersInvitingUser = invitingUser;
    },
    deletingUser(state: UsersState, action: Payload<{ userId: string | null }>) {
      const { userId } = action.payload;
      state.usersDeletingUser = userId;
    },
    unsetUser(state: UsersState, action: Payload<{ userId: string; millis: number }>) {
      const { userId, millis } = action.payload;
      delete state.users[userId];
      state.usersMillis = millis;
    },
  },
});
/*
 * exports
 */
export default usersSlice.reducer;
export const usersActions = usersSlice.actions;
