/*
 * imports
 */
import { usersActions } from './slice';
import { api } from '../../../Vendors/api';
// import { tap } from 'lodash';
// firebase imports
import {
  // firebase
  firebaseFunctions,
  firebaseFunctionsHttpsCallable,
  // firestore
  firestore,
  firestoreCollection,
  firestoreOnSnapshot,
} from '../../../Vendors/firebase';
// types
import type { RootState, UtilFunctions } from '../store.types';
import type { Users, User, UsersDeleteUserArgs, UserUpdatable } from './types';
import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';
import type { ToasterContextObj } from '../../Context/Toaster/types';
import type { FirestoreUnsubscribe, FirestoreQuerySnapshot } from '../../../Vendors/firebase/types';
/*
 *
 *
import logger from '@infobiotech/js-logger';
const componentName = 'users';
logger.setLogLevel(logger.INFO, componentName);
/*
 * init
 */
let usersFirestoreListener: FirestoreUnsubscribe | null = null;
export const usersDetachFirestoreListener = () => {
  if (usersFirestoreListener) {
    usersFirestoreListener();
    usersFirestoreListener = null;
  }
};
/**
 * users setup --- listen to firestore database users changes
 *
 * dispatch `mergeUsers` if any user is added or modified,
 *
 * dispatch `unsetUser` if any user is removed
 */
export const usersSetup = ({
  firestoreErrorHandler,
  makeErrorToast,
}: ToasterContextObj): ThunkAction<void, RootState, any, AnyAction> => {
  return async (dispatch, getState) => {
    // logger.info(componentName, 'usersSetup');
    usersDetachFirestoreListener();
    const authenticationUser = getState().authentication.authenticationUser;
    if (authenticationUser) {
      const isSuperUser = authenticationUser.isSuperUser === true;
      if (isSuperUser) {
        usersFirestoreListener = firestoreOnSnapshot<any>(
          firestoreCollection(firestore, 'users'),
          (snapshot: FirestoreQuerySnapshot<User>) => {
            const users = snapshot.docChanges().map(change => {
              const changedUser = change.doc.data();
              const changedUserId = change.doc.id;
              if (change.type === 'added') {
                return {
                  [changedUserId]: changedUser,
                };
              } else if (change.type === 'modified') {
                return {
                  [changedUserId]: changedUser,
                };
              } else if (change.type === 'removed') {
                dispatch(_unsetUser(changedUserId));
              }
              return {};
            });
            dispatch(
              _mergeUsers(
                users.reduce((usersObj, currUserObj) => ({ ...usersObj, ...currUserObj }))
              )
            );
          },
          firestoreErrorHandler({
            cleanupFn: () => {
              /* *
              logger.error(componentName, 'usersFirestoreListener', {
                code: snapshotError?.code,
                error: snapshotError,
                message: snapshotError?.message,
                name: snapshotError?.name,
              });
              /* */
              usersDetachFirestoreListener();
              // @todo force a reload ?})
            },
          })
        );
      }
    } else {
      makeErrorToast({ message: 'Error while users setup: authentication has not been set yet' });
    }
  };
};
/**
 * Update a user's profile
 *
export const updateUserData = (userData: UserFormData, userId?: string) => {
  if (userId) {
    return firebaseFunctionsHttpsCallable<
      // done
      {
        userId: string;
        displayName: string;
        photoURL: string | null;
        phoneNumber: string | null;
      },
      any
    >(
      firebaseFunctions,
      'userUpdate'
    )({ userId, ...userData });
  } else {
    throw new Error('User not authenticated. Please log in again.');
  }
};
/**
 * Update a user's profile [NEW API]
 */
export const updateUserData = (userData: UserUpdatable, userId?: string) => {
  if (userId) {
    return api.patch(`/users/${userId}`, { data: { ...userData } });
  } else {
    throw new Error('User not authenticated. Please log in again.');
  }
};
/**
 * update user role
 *
export const updateUserRoles = (userId: string, roles: string[]) => {
  if (userId) {
    return firebaseFunctionsHttpsCallable<
      // done
      {
        userId: string;
        roles: string[];
      },
      any
    >(
      firebaseFunctions,
      'userUpdate'
    )({ userId, roles });
  } else {
    throw new Error('User does not exist. Please try again');
  }
};
/**
 * update user role [NEW API]
 */
export const updateUserRoles = (userId: string, roles: string[]) => {
  if (userId) {
    return api.patch(`/users/${userId}/roles`, { roles });
  } else {
    throw new Error('User does not exist. Please try again');
  }
};
/**
 *
 *
export const updateUsers = users => {
  return async (_dispatch: AppDispatch, getState: GetRootState) => {
    const authenticationUser = getState().authentication.authenticationUser;
    const userId = authenticationUser?.userId || null;
    if (userId) {
      const isSuperUser = authenticationUser?.isSuperUser === true;
      if (isSuperUser) {
        try {
          await firebaseFunctionsHttpsCallable(firebaseFunctions, 'updateSystemUsers')({
            userId,
            users,
          });
        } catch (error) {
          // @error
        }
      }
    }
    return;
  };
};
/* */
/**
 * call cloud function `userInvite` to `emailAddress`
 */
export const usersInviteUser = (
  emailAddress: string
): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    try {
      dispatch(_invitingUser(true));
      const userInviteResponse = await firebaseFunctionsHttpsCallable<
        // done
        { emailAddress: string },
        any
      >(
        firebaseFunctions,
        'userInvite'
      )({
        emailAddress,
      });
      if (userInviteResponse?.data?.error) {
        dispatch(_invitingUser(false));
      } else {
        dispatch(_invitingUser(null));
      }
    } catch (error) {
      // logger.error(componentName, 'error found', error);
    }
    return;
  };
};
/**
 * call cloud function `userInvite` to `emailAddress` [NEW API]
 */
export const usersInviteUserAPI = (
  emailAddress: string
): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    try {
      dispatch(_invitingUser(true));
      let userInviteResponse = await api.post('/users/inviteUsers', {
        emailAddress,
      });
      if (userInviteResponse.status >= 400) {
        dispatch(_invitingUser(false));
      } else {
        dispatch(_invitingUser(null));
      }
    } catch (error) {
      // logger.error(componentName, 'error found', error);
    }
    return;
  };
};
/**
 * delete user with `userDelete` cloud function
 *
export const usersDeleteUser = (
  toasterCtx: ToasterContextObj | undefined = undefined,
  { userId }: UsersDeleteUserArgs,
  { successFn, cleanupFn }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    if (userId) {
      try {
        dispatch(_deletingUser(userId));
        const userDeleteResponse = await firebaseFunctionsHttpsCallable<{}, any>(
          // done
          firebaseFunctions,
          'userDelete'
        )({
          userId,
        });
        if (userDeleteResponse?.data?.error) {
          // @error
          const error = userDeleteResponse.data.error;
          const errMsg = error.errorInfo?.message || 'An error occurred';
          if (toasterCtx) {
            toasterCtx.makeErrorToast({ message: errMsg });
          }
          cleanupFn && cleanupFn();
        } else if (userDeleteResponse?.data !== true) {
          // @error
          if (toasterCtx) {
            toasterCtx.makeErrorToast();
          }
          cleanupFn && cleanupFn();
        } else {
          // done
          if (toasterCtx) {
            toasterCtx.makeSuccessToast({ message: `User ID#${userId} deleted successfully` });
          }
          successFn && successFn();
        }
      } catch (error: any) {
        // @error
        if (toasterCtx) {
          const errMsg = error.message || 'An error occurred';
          toasterCtx.makeErrorToast({ message: errMsg });
        }
        cleanupFn && cleanupFn();
      }
      dispatch(_deletingUser(null));
    } else {
      if (toasterCtx) {
        toasterCtx.makeErrorToast({ message: 'User not found. Please re-try it again later.' });
      }
      cleanupFn && cleanupFn();
    }
  };
};
/**
 * delete user with `userDelete` cloud function [NEW API]
 */
export const usersDeleteUser = (
  toasterCtx: ToasterContextObj | undefined = undefined,
  { userId }: UsersDeleteUserArgs,
  { cleanupFn, successFn }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async () => {
    if (userId) {
      try {
        // dispatch(_deletingUser(userId));
        const userDeleteResponse = await api.delete(`/users/${userId}`);
        if (userDeleteResponse.status >= 400) {
          // @error
          const error = userDeleteResponse.data;
          const errMsg = error.message || 'An error occurred';
          if (toasterCtx) {
            toasterCtx.makeErrorToast({ message: errMsg });
          }
          cleanupFn && cleanupFn();
        } else {
          // done
          if (toasterCtx) {
            toasterCtx.makeSuccessToast({
              message: userDeleteResponse.data.message || `User ID#${userId} deleted successfully`,
            });
          }
          successFn && successFn();
        }
      } catch (error: any) {
        if (toasterCtx) {
          const errMsg = error.message || 'An error occurred';
          toasterCtx.makeErrorToast({ message: errMsg });
        }
        cleanupFn && cleanupFn();
      }
      // dispatch(_deletingUser(null));
    } else {
      if (toasterCtx) {
        toasterCtx.makeErrorToast({ message: 'User not found. Please re-try it again later.' });
      }
      cleanupFn && cleanupFn();
    }
  };
};
/*
 * action creators
 */
const _invitingUser = (invitingUser: boolean | null) => {
  return usersActions.invitingUser({ invitingUser });
};
/*
 *
 *
const _deletingUser = (userId: string | null) => {
  return usersActions.deletingUser({ userId });
};
/*
 *
 */
const _unsetUser = (userId: string) => {
  const millis = Date.now();
  return usersActions.unsetUser({ userId, millis });
};
const _mergeUsers = (users: Users) => {
  const millis = Date.now();
  return usersActions.usersMerge({ users, millis });
};
