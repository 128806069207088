/*
 * imports
 */
import { createSlice } from '@reduxjs/toolkit';
// types
import type { Payload } from '../store.types';
import type { SystemState, SystemBroker, SystemConfiguration, SystemPolicies } from './types';
/*
 * initial state
 */
const initialSystemConfiguration: SystemConfiguration = {
  anonymousSignIn: false,
  passwordlessAuthentication: true,
  signUp: true,
  updatedMillis: 0,
  reCaptcha: {
    mode: 'easy',
    expiresIn: 0,
    siteKey: '',
  },
};
const initialSystemPolicies: SystemPolicies = {
  termsMillis: null,
  privacyMillis: null,
};
const systemInitialState: SystemState = {
  systemConfiguration: initialSystemConfiguration,
  // systemServices: {},
  systemPolicies: initialSystemPolicies,
  systemBroker: {},
  systemMillis: 0,
};
/*
 * system slice
 */
const systemSlice = createSlice({
  name: 'system',
  initialState: systemInitialState,
  reducers: {
    configurationMerge(
      state: SystemState,
      action: Payload<{ configuration: SystemConfiguration; millis: number }>
    ) {
      const { configuration, millis } = action.payload;
      state.systemConfiguration = { ...state.systemConfiguration, ...configuration };
      state.systemMillis = millis;
    },
    brokerMerge(state: SystemState, action: Payload<{ broker: SystemBroker; millis: number }>) {
      const { broker, millis } = action.payload;
      state.systemBroker = broker;
      state.systemMillis = millis;
    },
    policiesMerge(
      state: SystemState,
      action: Payload<{ policies: SystemPolicies; millis: number }>
    ) {
      const { policies, millis } = action.payload;
      state.systemPolicies = policies;
      state.systemMillis = millis;
    },
    /* *
    servicesMerge(state: SystemState, action: Payload<{ services: {}; millis: number }>) {
      const { services, millis } = action.payload;
      state.systemServices = { ...state.systemServices, ...services };
      state.systemMillis = millis;
    },
    /* */
  },
});
/*
 * exports
 */
export default systemSlice.reducer;
export const systemActions = systemSlice.actions;
