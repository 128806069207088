/*
 * imports
 */
/*
 * main
 */
const ROLE_NAMES = {
  admin: 'admin',
  kitsManager: 'kitsManager',
  operator: 'operator',
  operatorsManager: 'operatorsManager',
  operatorsSupervisor: 'operatorsSupervisor',
  usersManager: 'usersManager',
};
const ACCESS_CONTROL_NAMES = {
  badgeWriters: 'badgeWriters',
  kits: 'kits',
  diagnosticSessions: 'diagnosticSessions',
  operators: 'operators',
  roles: 'roles',
  users: 'users',
};
type RoleName = keyof typeof ROLE_NAMES;
type AccessControlName = keyof typeof ACCESS_CONTROL_NAMES;
type ACL = {
  [permissionName: string]: boolean;
};
type AccessControl = {
  // eslint-disable-next-line no-unused-vars
  [aclName in AccessControlName]?: ACL;
};
type Role = {
  accessControl: AccessControl | '*';
  enabled: boolean;
};
/*
 * state
 */
type RolesState = {
  // eslint-disable-next-line no-unused-vars
  [roleName in RoleName]?: Role;
};
/*
 * type guards
 */
const isRoleName = (name: string): name is RoleName => {
  return Object.keys(ROLE_NAMES).includes(name);
};
const isAccessControlName = (name: string): name is AccessControlName => {
  return Object.keys(ACCESS_CONTROL_NAMES).includes(name);
};
/*
 * exports
 */
export type { RolesState, RoleName, Role, AccessControlName, ACL, AccessControl };
export { isRoleName, isAccessControlName };
