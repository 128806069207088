/*
 * imports
 */
import './index.css';
// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Bootstrapper from './Bootstrapper';
import { BrowserRouter } from 'react-router-dom';
// store imports
import { Provider } from 'react-redux';
import store from './Store/Redux/store';
import ToasterContextProvider from './Store/Context/Toaster';
// blueprintjs imports
// other libraries
import './i18n'; // import i18n (needs to be bundled ;))
// import reportWebVitals from './reportWebVitals';
import CookieConsent from 'react-cookie-consent';
/*
 * render App
 */
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <StrictMode>
  <>
    <Provider store={store}>
      <ToasterContextProvider>
        <BrowserRouter>
          <Bootstrapper>
            <App />
          </Bootstrapper>
        </BrowserRouter>
      </ToasterContextProvider>
    </Provider>
    {
      // @ts-ignore
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="consoleCookieConsent"
        expires={60}
        onAccept={() => null}
        /**
         *
         */
        buttonClasses="bp3-button bp3-intent-primary bp3-large"
        containerClasses="py-2"
        buttonWrapperClasses=""
        contentClasses="py-2"
        /**
         *
       *
       disableStyles={true}
       /**
        *
        *
        overlay
        overlayClasses=""
        /**
         *
         *
         enableDeclineButton
         onDecline={() => null}
         declineButtonText="I decline"
         declineCookieValue
         setDeclineCookie
         declineButtonClasses=""
         declineButtonId
         flipButtons
         /**
          *
         *
          cookieValue=""
          extraCookieOptions
          buttonId
          /**
           *
           */
        debug={false}
      >
        <p>We use cookies to enhance user experience and provide support.</p>
        <p>
          Please read our <a href="/user/privacy">privacy policy</a> and our{' '}
          <a href="/user/terms">terms of use</a>.
        </p>
      </CookieConsent>
    }
  </>
  // </StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals( c o n s o l e . l o g ))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
