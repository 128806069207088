/*
 * imports
 */
import './global.scss';
// blueprintjs imports
import { FocusStyleManager } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
// types import
import type { FCWithChildren } from '../../../types';
import type { PageProps } from './types';
/*
 * init
 */
FocusStyleManager.onlyShowFocusOnTabs();
/**
 * Page Component
 */
const Page: FCWithChildren<PageProps> = props => {
  const {
    layout,
    header,
    children,
    footer,
    // ...restProps
  } = props;
  /**
   *
   */
  let outerAppClass = '';
  if (layout) {
    if ('centered' in layout && layout?.centered === true) {
      outerAppClass += ' centered';
    } else if ('withColumns' in layout && layout?.withColumns === true) {
      outerAppClass += ' withColumns';
    }
    if ('withPadding' in layout && layout?.withPadding === true) {
      outerAppClass += ' withPadding';
    }
    if ('withScrollableContent' in layout && layout?.withScrollableContent === true) {
      outerAppClass += ' withScrollableContent';
    }
  }
  return (
    <div id="outerPage">
      <div id="page" className={!layout?.fullWidth ? 'container' : ''}>
        <header>
          <div>{header || null}</div>
        </header>
        <main id="outerApp" className={outerAppClass}>
          {children}
        </main>
        <footer>{footer || null}</footer>
      </div>
    </div>
  );
};
/*
 * export
 */
export default Page;
