/* eslint-disable no-unused-vars */
/*
 * imports
 */
import { createContext, useState } from 'react';
import { createRoot } from 'react-dom/client';
// external libraries
import { Position, Toaster } from '@blueprintjs/core';
// types
import type { FCWithChildren } from '../../../types';
import type { FirebaseError } from 'firebase/app';
import type { FirestoreError } from 'firebase/firestore';
import type { ErrorHandlerArgs, Toast, ToastArgs, ToasterContextObj } from './types';
import type { BadgeWriter } from '../../Redux/badgeWriters/types';
/*
 * global variables
 */
/**
 * Toaster Context
 */
const ToasterContext = createContext<ToasterContextObj>({
  makeErrorToast: (toast?: ToastArgs) => {},
  makeWarningToast: (toast?: ToastArgs) => {},
  makeInfoToast: (toast?: ToastArgs) => {},
  makeSuccessToast: (toast?: ToastArgs) => {},
  makeDeviceErrorToast: (badgeWriter: BadgeWriter, toast?: ToastArgs) => {},
  clearDeviceErrorToast: (badgeWriterId: string) => {},
  errorHandler: (errorArgs?: ErrorHandlerArgs<any>) => () => {},
  firebaseErrorHandler: (errorArgs?: ErrorHandlerArgs<FirebaseError>) => () => {},
  firestoreErrorHandler: (errorArgs?: ErrorHandlerArgs<FirestoreError>) => () => {},
});
/**
 * App Toaster
 */
let appToaster: Toaster;
createRoot(document.getElementById('toaster')!).render(
  <Toaster
    position={Position.TOP}
    ref={instance => {
      appToaster = instance!;
    }}
  />
);
// const appToaster = Toaster.create({
//   position: Position.TOP,
// });
/*
 * global variables
 */
/**
 * Toaster Context Provider
 */
const ToasterContextProvider: FCWithChildren = props => {
  /*
   * local state
   */
  const [badgeWriterToasts, setBadgeWriterToasts] = useState<{ [badgeWriterId: string]: string }>(
    {}
  );
  /*
   * handlers
   */
  const clearDeviceErrorToast = (badgeWriterId: string) => {
    setBadgeWriterToasts(toasts => {
      appToaster.dismiss(toasts[badgeWriterId]);
      delete toasts[badgeWriterId];
      return toasts;
    });
  };
  /*
   * toaster helper functions
   */
  /**
   * Make an error toast
   * @param action to visualize in the toast
   * @param className classes to inherit
   * @param message text to visualize in the toast
   * @param onDismiss function to apply when dismissing manually
   * @param timeout timeout to dismiss automatically the toast
   * @returns
   */
  const makeDeviceErrorToast = (badgeWriter: BadgeWriter, toast?: ToastArgs) => {
    const { action, className, message, onDismiss, timeout } = toast || {};
    const onBadgeWriterErrorDismiss = (didTimeoutExpire: boolean) => {
      onDismiss && onDismiss(didTimeoutExpire);
      if (badgeWriter.error?.dismissible) {
        setBadgeWriterToasts(toasts => {
          // appToaster.dismiss(toasts[badgeWriter.id]); // it is already done
          delete toasts[badgeWriter.id];
          return toasts;
        });
      } else {
        // appToaster.show(errorToastProps, badgeWriterToasts[badgeWriter.id]);
      }
    };
    const toastId = badgeWriterToasts[badgeWriter.id];
    // clearDeviceErrorToast(badgeWriter.id);
    const errorToastProps: Toast = {
      action,
      className,
      icon: 'warning-sign',
      intent: 'danger',
      message: `BadgeWriter #${badgeWriter.id}: ${message || 'error'}`,
      onDismiss: onBadgeWriterErrorDismiss,
      timeout,
    };
    showDeviceErrorToast(badgeWriter.id, errorToastProps, toastId);
  };
  /**
   * Make an error toast
   * @param action to visualize in the toast
   * @param className classes to inherit
   * @param message text to visualize in the toast
   * @param onDismiss function to apply when dismissing manually
   * @param timeout timeout to dismiss automatically the toast
   * @returns
   */
  const makeErrorToast = (toast?: ToastArgs) => {
    const { action, className, message, onDismiss, timeout } = toast || {};
    const errorToastProps: Toast = {
      action,
      className,
      icon: 'error',
      intent: 'danger',
      message: message || 'An error occurred',
      onDismiss,
      timeout,
    };
    showToast(errorToastProps);
  };
  /**
   * Make a warning toast
   * @param action Action to visualize in the toast
   * @param className classes to inherit
   * @param message text to visualize in the toast
   * @param onDismiss function to apply when dismissing manually
   * @param timeout timeout to dismiss automatically the toast
   * @returns
   */
  const makeWarningToast = (toast?: ToastArgs) => {
    const { className, message, onDismiss, timeout } = toast || {};
    let action = toast?.action;
    action = action?.text ? action : { ...action, text: 'Got it' };
    const errorToastProps: Toast = {
      action,
      className,
      icon: 'warning-sign',
      intent: 'warning',
      message: message || 'Warning: possible error occurring.',
      onDismiss,
      timeout,
    };
    showToast(errorToastProps);
  };
  /**
   * Make an info toast with `primary` or `none` intent
   * @param action Action to visualize in the toast
   * @param className classes to inherit
   * @param message text to visualize in the toast
   * @param onDismiss function to apply when dismissing manually
   * @param timeout timeout to dismiss automatically the toast
   * @param primary select primary or none intent, primary is set to default
   */
  const makeInfoToast = (toast?: ToastArgs) => {
    const { action, className, message, onDismiss, timeout } = toast || {};
    let primary = toast?.primary === undefined || (toast && toast.primary);
    const infoToastProps: Toast = {
      action,
      className,
      icon: 'notifications',
      intent: primary ? 'primary' : 'none',
      message: message || 'General info',
      onDismiss,
      timeout,
    };
    showToast(infoToastProps);
  };
  /**
   * Make a success toast
   * @param action action to visualize in the toast
   * @param className classes to inherit
   * @param message text to visualize in the toast
   * @param onDismiss function to apply when dismissing manually
   * @param timeout timeout to dismiss automatically the toast
   */
  const makeSuccessToast = (toast?: ToastArgs) => {
    const { className, message, onDismiss, timeout } = toast || {};
    let action = toast?.action;
    action = action?.text ? action : { ...action, text: 'Got it' };
    const successToastProps: Toast = {
      action,
      className,
      icon: 'tick',
      intent: 'success',
      message: message || 'Operation success',
      onDismiss,
      timeout,
    };
    showToast(successToastProps);
  };
  const showToast = (toast: Toast) => {
    appToaster.show(toast);
  };
  const showDeviceErrorToast = (badgeWriterId: string, toast: Toast, toastId?: string) => {
    const badgeWriterToastId = appToaster.show(toast, toastId);
    if (!toastId) {
      setBadgeWriterToasts(toasts => ({ ...toasts, [badgeWriterId]: badgeWriterToastId }));
    }
  };
  /**
   * generic firebase error handler function
   */
  const firebaseErrorHandler = (errorArgs?: ErrorHandlerArgs<FirebaseError>) => {
    const message = errorArgs?.message;
    const cleanupFn = errorArgs?.cleanupFn || (() => {});
    return (error: FirebaseError) => {
      let errorMsg: string | undefined;
      if (typeof message === 'function') {
        errorMsg = message(error);
      } else {
        errorMsg = message;
      }
      makeErrorToast({ message: errorMsg || `${error.code} - ${error.message}` });
      cleanupFn();
    };
  };
  /**
   * generic firestore error handler function
   */
  const firestoreErrorHandler = (errorArgs?: ErrorHandlerArgs<FirestoreError>) => {
    const message = errorArgs?.message;
    const cleanupFn = errorArgs?.cleanupFn || (() => {});
    return (error: FirestoreError) => {
      let errorMsg: string | undefined;
      if (typeof message === 'function') {
        errorMsg = message(error);
      } else {
        errorMsg = message;
      }
      makeErrorToast({ message: errorMsg || `${error.code} - ${error.message}` });
      cleanupFn();
    };
  };
  /**
   * error handler function
   */
  const errorHandler = (errorArgs?: ErrorHandlerArgs<any>) => {
    const message = errorArgs?.message;
    const cleanupFn = errorArgs?.cleanupFn || (() => {});
    return (error: any) => {
      const errorMsg = error?.message || error?.error;
      makeErrorToast({ message: message || errorMsg || `An unknown error occurred` });
      cleanupFn();
    };
  };
  /**
   * context value
   */
  const contextValue: ToasterContextObj = {
    makeErrorToast,
    makeWarningToast,
    makeInfoToast,
    makeSuccessToast,
    makeDeviceErrorToast,
    clearDeviceErrorToast,
    errorHandler,
    firebaseErrorHandler,
    firestoreErrorHandler,
  };
  /*
   * render
   */
  return <ToasterContext.Provider value={contextValue}>{props.children}</ToasterContext.Provider>;
};
/*
 * exports
 */
export default ToasterContextProvider;
export { ToasterContext };
