/*
 * imports
 */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * use path hook
 */
const usePath = () => {
  /*
   * state
   */
  const path = useLocation().pathname;
  const [controller, setController] = useState<string | undefined>(undefined);
  const [entityPath, setEntityPath] = useState<string | undefined>(undefined);
  const [queryParams, setQueryParams] = useState<Map<string, string>>(new Map());
  /*
   * on change
   */
  useEffect(() => {
    const [noQueryPath, queryPath] = path.split('?');
    const newQueryParams: [string, string][] = queryPath?.split('&').map(pair => {
      const [key, value] = pair.split('=');
      return [key, value];
    });
    const [newController, ...newEntityPath] = noQueryPath.split('/').filter(path => path !== '');
    setController(newController);
    setEntityPath(newEntityPath.join('/'));
    setQueryParams(new Map(newQueryParams));
  }, [path]);
  /*
   * return
   */
  return { controller, entityPath, queryParams, path };
};
/*
 * exports
 */
export default usePath;
