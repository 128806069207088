/*
 * imports
 */
import configModule from '../../config.module';
import { Navigate } from 'react-router-dom';
// page imports
// redux imports
import { useSelector } from 'react-redux';
// types
import type { FCWithChildren } from '../../types';
import type { NotSignedInGuardProps } from './types';
import type { RootState } from '../../Store/Redux/store.types';
import type { User } from '../../Store/Redux/users/types';
/**
 * Component
 */
const NotSignedInGuard: FCWithChildren<NotSignedInGuardProps> = props => {
  /*
   * local state
   */
  const authenticationUser = useSelector<RootState, User | null | undefined>(
    state => state.authentication.authenticationUser
  );
  /*
   * useEffects
   */
  /*
   * render
   */
  if (!authenticationUser || (props.guest && authenticationUser.isAnonymous)) {
    return props.controller;
  }
  const continuePath = localStorage.getItem('continuePath')?.slice();
  const defaultPath = `/${configModule.controllers.defaultAfterSignIn}`;
  return (
    <Navigate
      to={!continuePath || continuePath === '/signOut' ? defaultPath : continuePath}
      replace={true}
    />
  );
};
/*
 * export
 */
export default NotSignedInGuard;
