/*
 * imports
 */
import { useEffect } from 'react';
import configModule from '../../config.module';
import { useNavigate } from 'react-router-dom';
// redux
import { useSelector } from 'react-redux';
// types
import type { FCWithChildren } from '../../types';
import type { PrivacyTermsGuardProps } from './types';
import type { AuthenticationState } from '../../Store/Redux/authentication/types';
import type { RootState } from '../../Store/Redux/store.types';
import type { SystemState } from '../../Store/Redux/system/types';
/*
 * global
 */
let isNavigate = false;
/**
 * Component
 */
const PrivacyTermsGuard: FCWithChildren<PrivacyTermsGuardProps> = props => {
  /*
   * redux
   */
  const { authenticationUser, authenticationUserMillis } = useSelector<
    RootState,
    AuthenticationState
  >(state => state.authentication);
  const { systemMillis, systemPolicies } = useSelector<RootState, SystemState>(
    state => state.system
  );
  /*
   * local state
   */
  const navigate = useNavigate();
  /*
   * useEffects
   */
  useEffect(() => {
    // if system policies and other data are already retrieved from database (is not null)
    if (
      authenticationUserMillis &&
      authenticationUser &&
      systemMillis &&
      systemPolicies.privacyMillis !== null &&
      systemPolicies.termsMillis !== null
    ) {
      // If it is the terms/privacy page then check if millis are already updated
      if (props.isPrivacyTerms) {
        if (
          authenticationUser.privacyMillis >= systemPolicies.privacyMillis &&
          authenticationUser.termsMillis >= systemPolicies.termsMillis
        ) {
          console.log('PrivacyTermsGuard navigate to defaultAfterSignIn');
          navigate(`/${configModule.controllers.defaultAfterSignIn}`);
        }
      } else {
        // I want to check if privacy millis are updated
        if (authenticationUser.privacyMillis < systemPolicies.privacyMillis) {
          navigate('/user/privacy');
          // already went to privacy
          isNavigate = true;
        }
        // I want to check if terms millis are updated
        // isNavigate is true if already navigated to privacy, though we don't want double navigate to terms
        if (!isNavigate) {
          if (authenticationUser.termsMillis < systemPolicies.termsMillis) {
            navigate('/user/terms');
          }
        }
        // reset variable for next time
        isNavigate = false;
      }
    }
  }, [authenticationUserMillis, systemMillis]);
  /*
   * content
   */
  console.log('PrivacyTermsGuard', {
    userPrivacy: authenticationUser?.privacyMillis,
    userTerms: authenticationUser?.termsMillis,
    systemPrivacy: systemPolicies.privacyMillis,
    systemTerms: systemPolicies.termsMillis,
  });
  /*
   * render
   */
  return props.controller;
};
/*
 * export
 */
export default PrivacyTermsGuard;
