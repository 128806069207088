/*
 * imports
 */
// pages
import Page from '../../Components/Page';
// blueprintjs import
import { NonIdealState } from '@blueprintjs/core';
// types
import type { FCWithChildren } from '../../../types';
import type { ErrorPageProps } from './types';
/**
 * Error Page
 */
const ErrorPage: FCWithChildren<ErrorPageProps> = props => {
  /*
   * local variables
   */
  const nonIdealStateAction = (
    <div className="bp3-button-group bp3-large ">
      <a href="/" role="button" className="bp3-button bp3-intent-primary ">
        <span className="bp3-icon-large bp3-icon-home" />
        <span>Home page</span>
      </a>
      <a href="/UserSupport" role="button" className="bp3-button bp3-intent-warning ">
        <span className="bp3-icon-large bp3-icon-help" />
        <span>User support</span>
      </a>
    </div>
  );
  const layout = {
    fullWidth: true,
    withPadding: true,
    centered: true,
  };
  const nonIdealStateDescription = <div>{props.description}</div>;
  /*
   * render
   */
  return (
    <Page layout={layout}>
      <NonIdealState
        icon={'error'}
        title={props.message || 'Unexpected error'}
        description={nonIdealStateDescription}
        action={nonIdealStateAction}
      />
    </Page>
  );
};
/*
 * export
 */
export default ErrorPage;
