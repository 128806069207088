/*
 * imports
 */
// page import
import NotFoundPage from '../../UI/Pages/NotFoundPage';
// types
import type { FCWithChildren } from '../../types';
/* *
import logger from '@infobiotech/js-logger';
const componentName = 'NotFoundController';
logger.setLogLevel(logger.INFO, componentName);
/* */
/**
 * NotFound Page Controller
 */
const NotFoundController: FCWithChildren = () => {
  return <NotFoundPage />;
};
/*
 * export
 */
export default NotFoundController;
