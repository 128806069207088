/*
 * imports
 */
import { useState } from 'react';
// other components imports
import SignedInPage from '../SignedInPage';
import SaaSPageNavigation from '../SaaSPageNavigation';
// blueprintjs import
import { Button } from '@blueprintjs/core';
// types import
import type { FCWithChildren } from '../../../types';
import type { PageProps as SaasPageProps } from '../Page/types';
/**
 * SaasPage Component
 */
const SaaSPage: FCWithChildren<SaasPageProps> = props => {
  /*
   * local state
   */
  const { header, layout, children, appTopBar } = props;
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const pageLayout = { ...layout, withColumns: true };
  const buttonClickHandler = () => {
    setShowSidebar(prevState => !prevState);
  };
  const sidebarNavigation = (
    <>
      {showSidebar && (
        <div id="navigationSidebar">
          <SaaSPageNavigation />
        </div>
      )}
      <Button
        type={'button'}
        icon={showSidebar ? 'menu-closed' : 'menu-open'}
        onClick={buttonClickHandler}
      />
    </>
  );
  /*
   * render
   */
  return (
    <SignedInPage
      header={header}
      // footer={<CustomFooter />}
      layout={pageLayout}
    >
      {sidebarNavigation}
      <div id="appContainer">
        <div id="appTopBar">{appTopBar || null}</div>
        <div id="appContent">{children || null}</div>
      </div>
    </SignedInPage>
  );
};
/*
 * export
 */
export default SaaSPage;
