/*
 * imports
 */
/*
 * types
 */
type ReadableTime = {
  seconds: number | null;
  minutes: number | null;
  hours: number | null;
  days: number | null;
  weeks: number | null;
  months: number | null;
  years: number | null;
};
type MillisToTimeUnitsArgs = {
  millis: number;
  maxUnit: string;
  minUnit: string;
};
/*
 * functions
 */
export const millisToTimeUnits = ({
  millis,
  maxUnit = 'years',
  minUnit = 'seconds',
}: MillisToTimeUnitsArgs) => {
  const readableTime: ReadableTime = {
    seconds: null,
    minutes: null,
    hours: null,
    days: null,
    weeks: null,
    months: null,
    years: null,
  };
  readableTime.seconds = Math.floor(millis / 1000);
  if (maxUnit !== 'seconds') {
    readableTime.minutes = Math.floor(readableTime.seconds / 60);
    readableTime.seconds = readableTime.seconds % 60;
    if (maxUnit !== 'minutes') {
      readableTime.hours = Math.floor(readableTime.minutes / 60);
      readableTime.minutes = readableTime.minutes % 60;
      if (maxUnit !== 'hours') {
        readableTime.days = Math.floor(readableTime.hours / 24);
        readableTime.hours = readableTime.hours % 24;
        if (maxUnit !== 'days') {
          readableTime.weeks = Math.floor(readableTime.days / 7);
          readableTime.days = readableTime.days % 7;
          if (maxUnit !== 'weeks') {
            readableTime.months = Math.floor(readableTime.weeks / 4);
            readableTime.weeks = readableTime.weeks % 4;
            if (maxUnit !== 'months') {
              readableTime.years = Math.floor(readableTime.months / 12);
              readableTime.months = readableTime.months % 12;
            }
          }
        }
      }
    }
  }
  if (minUnit !== 'seconds' && millis > 1000 * 60) {
    if (readableTime.minutes !== null && readableTime.seconds > (60 / 3) * 2) {
      readableTime.minutes++;
    }
    readableTime.seconds = null;
    if (minUnit !== 'minutes' && readableTime.minutes && millis > 1000 * 60 * 60) {
      if (readableTime.hours !== null && readableTime.minutes > (60 / 3) * 2) {
        readableTime.hours++;
      }
      readableTime.minutes = null;
      if (minUnit !== 'hours' && readableTime.hours && millis > 1000 * 60 * 60 * 24) {
        if (readableTime.days !== null && readableTime.hours > (24 / 3) * 2) {
          readableTime.days++;
        }
        readableTime.hours = null;
        if (minUnit !== 'days' && readableTime.days && millis > 1000 * 60 * 60 * 24 * 7) {
          if (readableTime.weeks !== null && readableTime.days > (7 / 3) * 2) {
            readableTime.weeks++;
          }
          readableTime.days = null;
          if (minUnit !== 'weeks' && readableTime.weeks && millis > 1000 * 60 * 60 * 24 * 7 * 4) {
            if (readableTime.months !== null && readableTime.weeks > 2) {
              readableTime.months++;
            }
            readableTime.weeks = null;
            if (
              minUnit !== 'months' &&
              readableTime.months &&
              millis > 1000 * 60 * 60 * 24 * 7 * 4 * 12
            ) {
              if (readableTime.years !== null && readableTime.months > 8) {
                readableTime.years++;
              }
              readableTime.months = null;
            } else {
              // months
            }
          } else {
            // weeks
          }
        } else {
          // days
        }
      } else {
        // hours
      }
    } else {
      // minutes
    }
  } else {
    // seconds
  }
  return readableTime;
};
type SecondsToTimeUnitsArgs = {
  seconds: number;
  maxUnit: string;
  minUnit: string;
};
export const secondsToTimeUnits = ({
  seconds,
  maxUnit = 'years',
  minUnit = 'seconds',
}: SecondsToTimeUnitsArgs) => {
  return millisToTimeUnits({ millis: seconds * 1000, maxUnit, minUnit });
};
/**
 *
 */
type ReadableTimeArgs = {
  millis: number | null;
  seconds?: number | null;
  maxUnit?: string;
  minUnit?: string;
  shortTimeUnitsLabels?: boolean;
  finishingString?: string;
};
export const readableTime = ({
  millis = null,
  seconds = null,
  maxUnit = 'years',
  minUnit = 'seconds',
  shortTimeUnitsLabels = true,
  finishingString = '',
}: // timeRemaining = false,
ReadableTimeArgs) => {
  const readableRemainingTimeUnits = millis
    ? millisToTimeUnits({ millis, maxUnit, minUnit })
    : seconds
    ? secondsToTimeUnits({ seconds, maxUnit, minUnit })
    : millisToTimeUnits({ millis: Date.now(), maxUnit, minUnit });
  let readableTimeString = '';
  if (readableRemainingTimeUnits.years && readableRemainingTimeUnits.years > 0) {
    readableTimeString += `${readableRemainingTimeUnits.years}${
      shortTimeUnitsLabels ? 'y' : ` year${readableRemainingTimeUnits.years > 1 ? 's' : ''}`
    } `;
  }
  if (readableRemainingTimeUnits.months && readableRemainingTimeUnits.months > 0) {
    readableTimeString += `${readableRemainingTimeUnits.months}${
      shortTimeUnitsLabels ? 'mo' : ` month${readableRemainingTimeUnits.months > 1 ? 's' : ''}`
    } `;
  }
  if (readableRemainingTimeUnits?.weeks && readableRemainingTimeUnits.weeks > 0) {
    readableTimeString += `${readableRemainingTimeUnits.weeks}${
      shortTimeUnitsLabels ? 'w' : ` week${readableRemainingTimeUnits.weeks > 1 ? 's' : ''}`
    } `;
  }
  if (readableRemainingTimeUnits?.days && readableRemainingTimeUnits.days > 0) {
    readableTimeString += `${readableRemainingTimeUnits.days}${
      shortTimeUnitsLabels ? 'd' : ` day${readableRemainingTimeUnits.days > 1 ? 's' : ''}`
    } `;
  }
  if (readableRemainingTimeUnits.hours && readableRemainingTimeUnits.hours > 0) {
    readableTimeString += `${readableRemainingTimeUnits.hours}${
      shortTimeUnitsLabels ? 'h' : ` hour${readableRemainingTimeUnits.hours > 1 ? 's' : ''}`
    } `;
  }
  if (readableRemainingTimeUnits.minutes && readableRemainingTimeUnits.minutes > 0) {
    /*
     *
     *
    if (
      timeRemaining &&
      !readableRemainingTimeUnits.seconds &&
      readableRemainingTimeUnits.minutes < 59
    ) {
      readableRemainingTimeUnits.minutes++;
    }
    /*
     *
     */
    readableTimeString += `${readableRemainingTimeUnits.minutes}${
      shortTimeUnitsLabels ? 'm' : ` minute${readableRemainingTimeUnits.minutes > 1 ? 's' : ''}`
    } `;
  }
  if (readableRemainingTimeUnits.seconds && readableRemainingTimeUnits.seconds > 0) {
    /*
     *
     *
    if (timeRemaining && readableRemainingTimeUnits.seconds < 59) {
      readableRemainingTimeUnits.seconds++;
    }
    /*
     *
     */
    readableTimeString += `${readableRemainingTimeUnits.seconds}${
      shortTimeUnitsLabels ? 's' : ` second${readableRemainingTimeUnits.seconds > 1 ? 's' : ''}`
    } `;
  } else if (readableTimeString.trim() === '') {
    readableTimeString = finishingString || '';
  }
  return readableTimeString.trim();
};
/*
Formats the given number using `Number#toLocaleString`.
- If locale is a string, the value is expected to be a locale-key (for example: `de`).
- If locale is true, the system default locale is used for translation.
- If no value for locale is specified, the number is returned unmodified.
*/
export const toLocaleString = (
  number: number,
  locale?: string | string[],
  options?: Intl.NumberFormatOptions
) => {
  let result = number.toString();
  if (typeof locale === 'string' || Array.isArray(locale)) {
    result = number.toLocaleString(locale, options);
  } else if (locale === true || options !== undefined) {
    result = number.toLocaleString(undefined, options);
  }
  return result;
};
/**
 */
type ReadableQuantityOptions = {
  items?: boolean;
  bits?: boolean;
  binary?: boolean;
  withSpace?: boolean;
  signed?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  locale?: string | string[];
};
export const readableQuantity = (number: number, options: ReadableQuantityOptions) => {
  if (!Number.isFinite(number)) {
    throw new TypeError(`Expected a finite number, got ${typeof number}: ${number}`);
  }
  const ITEMS_UNITS = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  const BYTE_UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const BIBYTE_UNITS = ['B', 'kiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  const BIT_UNITS = ['b', 'kbit', 'Mbit', 'Gbit', 'Tbit', 'Pbit', 'Ebit', 'Zbit', 'Ybit'];
  const BIBIT_UNITS = ['b', 'kibit', 'Mibit', 'Gibit', 'Tibit', 'Pibit', 'Eibit', 'Zibit', 'Yibit'];
  options = Object.assign({ items: false, bits: false, binary: false, withSpace: true }, options);
  const UNITS = options.items
    ? ITEMS_UNITS
    : options.bits
    ? options.binary
      ? BIBIT_UNITS
      : BIT_UNITS
    : options.binary
    ? BIBYTE_UNITS
    : BYTE_UNITS;
  if (options.signed && number === 0) {
    return ` 0${options.withSpace ? ' ' : ''}${UNITS[0]}`.trim();
  }
  const isNegative = number < 0;
  const prefix = isNegative ? '-' : options.signed ? '+' : '';
  if (isNegative) {
    number = -number;
  }
  let localeOptions: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  } = {};
  if (options.minimumFractionDigits !== undefined) {
    localeOptions = { minimumFractionDigits: options.minimumFractionDigits };
  }
  if (options.maximumFractionDigits !== undefined) {
    localeOptions = Object.assign(
      { maximumFractionDigits: options.maximumFractionDigits },
      localeOptions
    );
  }
  if (number < 1) {
    const numberString = toLocaleString(number, options.locale, localeOptions);
    // return (prefix + numberString + ' ' + UNITS[0]).trim();
    return `${prefix}${numberString}${options.withSpace ? ' ' : ''}${UNITS[0]}`.trim();
  }
  const exponent = Math.min(
    Math.floor(options.binary ? Math.log(number) / Math.log(1024) : Math.log10(number) / 3),
    UNITS.length - 1
  );
  number /= Math.pow(options.binary ? 1024 : 1000, exponent);
  if (!localeOptions) {
    number = +number.toPrecision(3);
  }
  const numberString = toLocaleString(Number(number), options.locale, localeOptions);
  const unit = UNITS[exponent];
  // return (prefix + numberString + ' ' + unit).trim();
  return `${prefix}${numberString}${options.withSpace ? ' ' : ''}${unit}`.trim();
};
/**
 *
 * @param {string} str
 * @returns string from 'camelCase' to 'Camel Case'
 */
export const CamelCase2String = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, str => {
    return str.toUpperCase();
  });
};
/**
 *
 * @param {number} val
 * @param {number} min
 * @param {number} max
 * @returns {number} min(max, max(val, min))
 */
export const clamp = (val: number, min: number, max: number) => {
  if (val === null) {
    return val;
  }
  return Math.min(Math.max(val, min), max);
};
export const ONE_SECOND_MILLIS = 1000;
export const ONE_MINUTE_MILLIS = 1000 * 60;
export const ONE_HOUR_MILLIS = 1000 * 60 * 60;
export const ONE_DAY_MILLIS = 1000 * 60 * 60 * 24;
export const ONE_WEEK_MILLIS = 1000 * 60 * 60 * 24 * 7;
export const ONE_MONTH_MILLIS = 1000 * 60 * 60 * 24 * 30;
export const ONE_YEAR_MILLIS = 1000 * 60 * 60 * 24 * 365;
/**
 * gives back a string formatted in `{time} {format}` like `0.5 day` or `1 month` or `32 days`
 * @param millis
 * @param options format options it includes
 *  `zeroCase` - the string that should be shown if millis is 0. default is "Zero"
 *  `format` fixed format and can be 'sec', 'min', 'hr', 'day', 'mon', 'yr', by default it changes based on the millis
 * @returns
 */
export const getFormattedTime = (
  millis: number,
  options: { zeroCase: string; format?: 'sec' | 'min' | 'hr' | 'day' | 'mon' | 'yr' } = {
    zeroCase: 'Zero',
  }
) => {
  let format: number;
  switch (options.format) {
    case 'sec':
      format = ONE_SECOND_MILLIS;
      break;
    case 'min':
      format = ONE_MINUTE_MILLIS;
      break;
    case 'hr':
      format = ONE_HOUR_MILLIS;
      break;
    case 'day':
      format = ONE_DAY_MILLIS;
      break;
    case 'mon':
      format = ONE_MONTH_MILLIS;
      break;
    case 'yr':
      format = ONE_YEAR_MILLIS;
      break;
    default:
      format = -1;
      break;
  }
  if (millis > 0) {
    let formatText: string;
    let total: number;
    if (options.format && format) {
      total = millis / format;
      formatText = options.format;
    } else {
      if (millis > ONE_MINUTE_MILLIS) {
        if (millis > ONE_HOUR_MILLIS) {
          if (millis > ONE_DAY_MILLIS) {
            if (millis > ONE_MONTH_MILLIS) {
              if (millis > ONE_YEAR_MILLIS) {
                total = millis / ONE_YEAR_MILLIS;
                formatText = 'yr';
              } else {
                total = millis / ONE_MONTH_MILLIS;
                formatText = 'mon';
              }
            } else {
              total = millis / ONE_DAY_MILLIS;
              formatText = 'day';
            }
          } else {
            total = millis / ONE_HOUR_MILLIS;
            formatText = 'hr';
          }
        } else {
          total = millis / ONE_MINUTE_MILLIS;
          formatText = 'min';
        }
      } else {
        total = millis / ONE_SECOND_MILLIS;
        formatText = 'sec';
      }
    }
    return `${total} ${total > 1 ? formatText + 's' : formatText}`; // ex. 32.2 hrs -- 0.7 day
  } else {
    return options.zeroCase;
  }
};
/**
 * @returns `true` if `str` is a valid key, `false` otherwise
 */
export const isValidKey = (str: string) => {
  return str.length === 20;
  /* *
  const matched = str.match(/^[A-F0-9]{32}$/);
  if (matched) {
    return matched.length > 0;
  } else {
    return false;
  }
  /** */
};
/**
 * get a custom date format '{day}, {hh}:{mm}:{ss}' where {day} can be 'Today', 'Yesterday', or {dd/mm/yyyy}
 * @param millis
 * @returns {string}
 */
export const getCustomDate = (millis: number): string => {
  const date = new Date(millis);
  const currentDate = new Date();
  let day: string;
  if (currentDate.getDay() === date.getDay()) {
    day = 'Today';
  } else if (currentDate.getDay() === new Date(millis + ONE_DAY_MILLIS).getDay()) {
    day = 'Yesterday';
  } else {
    day = date.toLocaleDateString();
  }
  const time = date.toLocaleTimeString();
  return `${day}, ${time}`;
};
export const dec2hex = (dec: number) => {
  const hexString = dec.toString(16);
  return `${hexString.length > 1 ? '' : '0'}${hexString}`;
};
export const hex2dec = (hex: string) => {
  const hexString = hex.replace(/[^0-9A-Fa-f]/g, '');
  return parseInt(hexString, 16);
};
