/*
 * imports
 */
// page import
import AboutPage from '../../UI/Pages/AboutPage';
// types
import type { FCWithChildren } from '../../types';
/**
 * About Page Controller
 */
const AboutController: FCWithChildren = () => {
  return <AboutPage />;
};
/*
 * export
 */
export default AboutController;
