/*
 * imports
 */
import { systemActions } from './slice';
// import { api } from '../../../Vendors/api';
// firebase imports
import {
  // firebase
  firebaseFunctionsHttpsCallable,
  firebaseFunctions,
  // firestore
  firestore,
  firestoreDoc,
  firestoreOnSnapshot,
} from '../../../Vendors/firebase';
// types
import type { RootState } from '../store.types';
import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';
import type { SystemConfigUpdateArgs, SystemConfiguration, SystemPolicies } from './types';
import type { ToasterContextObj } from '../../Context/Toaster/types';
import type {
  FirestoreUnsubscribe,
  FirestoreDocumentSnapshot,
} from '../../../Vendors/firebase/types';
/*
 *
 *
import logger from '@infobiotech/js-logger';
const componentName = 'system';
logger.setLogLevel(logger.INFO, componentName);
/*
 *
 */
let systemConfigurationFirestoreListener: FirestoreUnsubscribe | null = null;
const detachSystemConfigurationFirestoreListener = () => {
  if (systemConfigurationFirestoreListener) {
    systemConfigurationFirestoreListener();
    systemConfigurationFirestoreListener = null;
  }
};
/*
 *
 */
let systemPoliciesFirestoreListener: FirestoreUnsubscribe | null = null;
const detachSystemPoliciesFirestoreListener = () => {
  if (systemPoliciesFirestoreListener) {
    systemPoliciesFirestoreListener();
    systemPoliciesFirestoreListener = null;
  }
};
/*
 *
 *
let systemBrokerFirestoreListener = null;
const detachSystemBrokerFirestoreListener = () => {
  if (systemBrokerFirestoreListener) {
    systemBrokerFirestoreListener();
    systemBrokerFirestoreListener = null;
  }
};
/*
 *
 *
let systemServicesFirestoreListener = null;
const detachSystemServicesFirestoreListener = () => {
  if (systemServicesFirestoreListener) {
    detachSystemServicesFirestoreListener();
    systemServicesFirestoreListener = null;
  }
};
/**
 * system setup
 */
export const systemSetup = ({
  firestoreErrorHandler,
}: ToasterContextObj): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    // logger.info(componentName, 'systemSetup');
    /*
     *
     */
    detachSystemConfigurationFirestoreListener();
    systemConfigurationFirestoreListener = firestoreOnSnapshot<any>(
      firestoreDoc(firestore, 'system', 'configuration'),
      (documentSnapshot: FirestoreDocumentSnapshot<SystemConfiguration>) => {
        if (documentSnapshot.exists()) {
          const configuration = { ...documentSnapshot.data() };
          // logger.info(componentName, 'systemConfigurationFirestoreListener', { configuration });
          dispatch(_mergeConfiguration(configuration));
        }
      },
      firestoreErrorHandler({
        cleanupFn: () => {
          /* *
        logger.error(componentName, 'systemConfigurationFirestoreListener', {
          code: snapshotError?.code,
          error: snapshotError,
          message: snapshotError?.message,
          name: snapshotError?.name,
        });
        /* */
          detachSystemConfigurationFirestoreListener();
          // @todo force a reload
        },
      })
    );
    /*
     *
     */
    detachSystemPoliciesFirestoreListener();
    systemPoliciesFirestoreListener = firestoreOnSnapshot<any>(
      firestoreDoc(firestore, 'system', 'policies'),
      (documentSnapshot: FirestoreDocumentSnapshot<SystemPolicies>) => {
        if (documentSnapshot.exists()) {
          const configuration = { ...documentSnapshot.data() };
          // logger.info(componentName, 'systemPoliciesFirestoreListener', { configuration });
          dispatch(_mergePolicies(configuration));
        }
      },
      firestoreErrorHandler({
        cleanupFn: () => {
          /* *
       logger.error(componentName, 'systemPoliciesFirestoreListener', {
         code: snapshotError?.code,
         error: snapshotError,
         message: snapshotError?.message,
         name: snapshotError?.name,
       });
       /* */
          detachSystemPoliciesFirestoreListener();
          // @todo force a reload
        },
      })
    );
    /*
     *
     *
    detachSystemBrokerFirestoreListener();
    systemBrokerFirestoreListener = firestore
      .collection('system')
      .doc('broker')
      .onSnapshot(
        documentSnapshot => {
          if (documentSnapshot?.exists) {
            const broker = { ...documentSnapshot.data() };
            // logger.info(componentName, 'systemBrokerFirestoreListener', {broker});
            dispatch(_mergeBroker(broker));
          }
        },
        snapshotError => {
          logger.error(componentName, 'systemBrokerFirestoreListener', {
            code: snapshotError?.code,
            error: snapshotError,
            message: snapshotError?.message,
            name: snapshotError?.name,
          });
          detachSystemBrokerFirestoreListener();
          // @todo force a reload
        }
      );
    /*
     *
     *
    detachSystemServicesFirestoreListener();
    systemServicesFirestoreListener = firestoreCollection(firestore, 'system')
      .doc('services')
      .onSnapshot(
        documentSnapshot => {
          if (documentSnapshot?.exists) {
            const services = { ...documentSnapshot.data() };
            // logger.info(componentName, 'systemServicesFirestoreListener', {services});
            dispatch(_mergeServices(services));
          }
        },
        snapshotError => {
          logger.error(componentName, 'systemServicesFirestoreListener', {
            code: snapshotError?.code,
            error: snapshotError,
            message: snapshotError?.message,
            name: snapshotError?.name,
          });
          detachSystemServicesFirestoreListener();
          // @todo force a reload
        }
      );
    /*
     *
     */
  };
};
/**
 * system configuration update
 *
 * update the old config with a new `configuration`
 */
export const systemConfigurationUpdate = (
  { makeErrorToast, makeSuccessToast, errorHandler }: ToasterContextObj,
  configuration: SystemConfigUpdateArgs
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const authenticationUser = getState().authentication.authenticationUser;
    const userId = authenticationUser?.userId;
    if (userId) {
      /**
       * @todo implement proper role-based ACL
       */
      const isSuperUser = authenticationUser?.isSuperUser === true;
      if (isSuperUser) {
        /** firebase callables */
        firebaseFunctionsHttpsCallable<
          // done
          { userId: string; configuration: SystemConfigUpdateArgs },
          any
        >(
          firebaseFunctions,
          'systemConfigurationUpdate'
        )({
          userId,
          configuration,
        })
          .then(systemConfigurationUpdateResponse => {
            if (systemConfigurationUpdateResponse?.data?.error) {
              // error
              const errorMsg = systemConfigurationUpdateResponse.data.error;
              makeErrorToast({ message: `Error: ${errorMsg}` });
            } else if (systemConfigurationUpdateResponse?.data !== true) {
              // error
              makeErrorToast({ message: 'An error occurred while updating configuration' });
            } else {
              // ok
              makeSuccessToast({ message: 'Configuration updated successfully.' });
            }
          })
          .catch(errorHandler());
        /** [NEW API] *
        api
          .patch('/system/configuration', {
            configuration,
          })
          .then(response => {
            if (response.status >= 400) {
              // error
              const errorMsg = response.data.message;
              makeErrorToast({ message: `Error: ${errorMsg}` });
            } else {
              // ok
              makeSuccessToast({ message: 'Configuration updated successfully.' });
            }
          })
          .catch(errorHandler());
        /** */
      }
    } else {
      makeErrorToast({
        message: 'You are not logged in. There is an error with your account. Please log in again',
      });
    }
  };
};
/*
 * action creators
 */
const _mergeConfiguration = (configuration: SystemConfiguration) => {
  const millis = Date.now();
  return systemActions.configurationMerge({
    configuration,
    millis,
  });
};
/*
 *
 */
const _mergePolicies = (policies: SystemPolicies) => {
  const millis = Date.now();
  return systemActions.policiesMerge({
    policies,
    millis,
  });
};
/*
 *
 *
const _mergeBroker = broker => {
  const millis = Date.now();
  return systemActions.brokerMerge({
    broker,
    millis,
  });
};
/*
 *
 *
const _mergeServices = services => {
  const millis = Date.now();
  return systemActions.servicesMerge({
    services,
    millis,
  });
};
/*
 *
 */
