/*
 * imports
 */
// pages
import UnauthorizedPage from '../../UI/Pages/UnauthorizedPage';
// types
import type { FCWithChildren } from '../../types';
/* *
import logger from '@infobiotech/js-logger';
const componentName = 'UnauthorizedController';
logger.setLogLevel(logger.INFO, componentName);
/* */
/**
 * Unauthorized Page Controller
 */
const UnauthorizedController: FCWithChildren = () => {
  return <UnauthorizedPage />;
};
/*
 * export
 */
export default UnauthorizedController;
