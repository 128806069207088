/*
 * imports
 */
// router imports
import { useNavigate } from 'react-router-dom';
import usePath from '../../../Hooks/use-path';
// blueprintjs import
import { Navbar, Button, NavbarGroup, Alignment, NavbarDivider } from '@blueprintjs/core';
// types
import type { FCWithChildren } from '../../../types';
/* *
import logger from '@infobiotech/js-logger';
const componentName = 'PageFooter';
logger.setLogLevel(logger.INFO, componentName);
/* */
const copyrightText = `© 2014-${new Date().getFullYear()} Infobiotech`;
/**
 * Page Footer Component
 */
const PageFooter: FCWithChildren = () => {
  // const componentName = 'PageFooter';
  /*
   * Redux store
   */
  const currController = usePath().controller;
  /*
   * local state
   */
  const navigate = useNavigate();
  /**
   *
   */
  const buttonPropsForPath = (path: string) => {
    return {
      onClick: () => {
        if (currController !== path) {
          navigate(`/${path}`);
        }
      },
      disabled: currController === path,
      active: currController === path,
    };
  };
  /*
   * render
   */
  return (
    <Navbar>
      <NavbarGroup align={Alignment.LEFT}>
        <small>{copyrightText}</small>
        {/* <Button minimal={true} icon={'info-sign'} text="About" {...buttonPropsForPath('about')} /> */}
      </NavbarGroup>
      <Navbar.Group align={Alignment.RIGHT}>
        {/*
        <Button
          intent={'primary'}
          icon={'credit-card'}
          text="Contribute"
          {...buttonPropsForPath('contribute')}
        /> */}
        <Button
          minimal={true}
          icon={'small-tick'}
          text="Terms of Service"
          {...buttonPropsForPath('user/terms')}
        />
        <Button
          minimal={true}
          icon={'shield'}
          text="Privacy and GDPR"
          {...buttonPropsForPath('user/privacy')}
        />
        <Button
          intent={'warning'}
          icon={'help'}
          text="Technical Support"
          {...buttonPropsForPath('support')}
        />
        {window.location.hostname === 'localhost' && (
          <>
            <NavbarDivider />
            <small className="small d-block d-sm-none">XS</small>
            <small className="small d-none d-sm-block d-md-none">SM</small>
            <small className="small d-none d-md-block d-lg-none">MD</small>
            <small className="small d-none d-lg-block d-xl-none">LG</small>
            <small className="small d-none d-xl-block d-xxl-none">XL</small>
            <small className="small d-none d-xxl-block">XXL</small>
          </>
        )}
      </Navbar.Group>
    </Navbar>
  );
};
/*
 * export
 */
export default PageFooter;
