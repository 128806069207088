/*
 * imports
 */
import { createSlice } from '@reduxjs/toolkit';
/*
 * types
 */
import type { Payload } from '../store.types';
import type { AuthenticationState } from './types';
import type { User, UserArgs } from '../users/types';
/**
 * initial state
 */
export const authenticationInitialState: AuthenticationState = {
  authenticationUser: null,
  authenticationProviders: [],
  authenticationUserMillis: 0,
  authenticationSigningIn: false,
  authenticationSigningUp: false,
  authenticationSignInLinkToEmail: null,
};
/**
 * authentication slice
 */
const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: authenticationInitialState,
  reducers: {
    toggleSigningIn(state: AuthenticationState, action: Payload<{ signingIn?: boolean }>) {
      let newSigningIn = action.payload.signingIn;
      if (newSigningIn === undefined) {
        newSigningIn = !state.authenticationSigningIn;
      }
      state.authenticationSigningIn = newSigningIn;
    },
    toggleSigningUp(state: AuthenticationState, action: Payload<{ signingUp?: boolean }>) {
      let newSigningUp = action.payload.signingUp;
      if (newSigningUp === undefined) {
        newSigningUp = !state.authenticationSigningUp;
      }
      state.authenticationSigningUp = newSigningUp;
    },
    setUser(
      state: AuthenticationState,
      action: Payload<{ user: User | undefined; millis: number }>
    ) {
      const { user, millis } = action.payload;
      state.authenticationUser = user;
      state.authenticationUserMillis = millis;
    },
    setSignInLinkToEmail(state: AuthenticationState, action: Payload<{ emailAddress: string }>) {
      const { emailAddress } = action.payload;
      state.authenticationSignInLinkToEmail = emailAddress;
    },
    setProviderData(state: AuthenticationState, action: Payload<{ providers: string[] }>) {
      const { providers } = action.payload;
      state.authenticationProviders = providers;
    },
    addProvider(state: AuthenticationState, action: Payload<{ provider: string }>) {
      const { provider } = action.payload;
      state.authenticationProviders.push(provider);
    },
    removeProvider(state: AuthenticationState, action: Payload<{ provider: string }>) {
      const { provider } = action.payload;
      state.authenticationProviders = state.authenticationProviders.filter(
        authenticationProvider => authenticationProvider !== provider
      );
    },
    updateUser(state: AuthenticationState, action: Payload<{ user: UserArgs; millis: number }>) {
      const { user, millis } = action.payload;
      if (state.authenticationUser) {
        state.authenticationUser = {
          ...state.authenticationUser,
          ...user,
        };
        state.authenticationUserMillis = millis;
      }
    },
  },
});
/*
 * exports
 */
export const authenticationActions = authenticationSlice.actions;
export default authenticationSlice.reducer;
