/*
 * import
 */
import axios from 'axios';
// types
import type {
  Authentication,
  HttpConfig,
  HttpInstance,
  HttpRequestConfig,
  HttpResponse,
  ResponseData,
} from './types';
import type { Auth } from 'firebase/auth';
/*
 * class
 */
class IbtHttp {
  private http: HttpInstance;
  private static auth?: Auth;
  // constructor
  constructor(config?: HttpConfig) {
    if (config?.authentication) {
      IbtHttp.auth = config.authentication.firebaseAuth;
    }
    this.http = axios.create({
      ...config,
      baseURL: config?.baseURL,
      timeout: config?.timeout,
      validateStatus: status => status >= 100 && status < 600,
    });
  }
  /**
   * request method
   * @param path path can be either URL or PATH
   * @param config request config object
   * @returns promise
   */
  request = async (path?: string, config?: HttpRequestConfig, data?: any) => {
    const headers = { ...(config || {}).headers };
    const parsedPath = path?.trim() || '';
    if (IbtHttp.auth) {
      const token = await IbtHttp.auth.currentUser?.getIdToken();
      if (token) {
        headers['x-firebase-auth-token'] = token;
      }
    }
    if (config?.method === 'post') {
      return this.http.post<ResponseData, HttpResponse>(parsedPath, data, {
        ...config,
        headers,
      });
    } else if (config?.method === 'patch') {
      return this.http.patch<ResponseData, HttpResponse>(parsedPath, data, {
        ...config,
        headers,
      });
    } else if (config?.method === 'delete') {
      return this.http.delete<ResponseData, HttpResponse>(parsedPath, {
        ...config,
        headers,
      });
    } else {
      return this.http.get<ResponseData, HttpResponse>(parsedPath, {
        ...config,
        headers,
      });
    }
  };
  /**
   * post method (shortcut to request)
   * @param path if undefined it will call the baseURL
   * @param data
   * @param config
   * @returns
   */
  post = async (path?: string, data?: any, config?: HttpRequestConfig) => {
    return this.request(path, { ...config, method: 'post' }, data);
  };
  /**
   * post method (shortcut to request)
   * @param path if undefined it will call the baseURL
   * @param data
   * @param config
   * @returns
   */
  patch = async (path?: string, data?: any, config?: HttpRequestConfig) => {
    return this.request(path, { ...config, method: 'patch' }, data);
  };
  /**
   * get method (shortcut to request)
   * @param path if undefined it will call the baseURL
   * @param config
   * @returns
   */
  get = async (path?: string, config?: HttpRequestConfig) => {
    return this.request(path, { ...config, method: 'get' });
  };
  /**
   * delete method (shortcut to request)
   * @param path if undefined it will call the baseURL
   * @param config
   * @returns
   */
  delete = async (path?: string, config?: HttpRequestConfig) => {
    return this.request(path, { ...config, method: 'delete' });
  };
  /**
   * set auth token
   */
  static setAuth = (auth: Authentication) => {
    if (auth) {
      IbtHttp.auth = auth.firebaseAuth;
    }
  };
}
/*
 * export
 */
export default IbtHttp;
