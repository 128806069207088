/*
 * imports
 */
import IbtHttp from '../../Classes/IbtHttp';
// types
import type { HttpConfig } from '../../Classes/IbtHttp/types';
/*
 * config
 */
const DEFAULT_CONFIG: HttpConfig = {
  baseURL: 'https://api4.icgene.com',
  timeout: 2000,
};
const setAuth = IbtHttp.setAuth;
/*
 * instance
 */
const api = new IbtHttp(DEFAULT_CONFIG);
/*
 * exports
 */
export { api, setAuth };
