/*
 * imports
 */
// import { Navigate } from 'react-router-dom';
// pages imports
// controllers imports
import NotFoundController from '../../Controllers/NotFoundController';
// redux imports
import { useSelector } from 'react-redux';
// guards
import PrivacyTermsGuard from '../PrivacyTermsGuard';
// types
import type { FCWithChildren } from '../../types';
import type { AccessControlGuardProps } from './types';
import type { RootState } from '../../Store/Redux/store.types';
import type { User } from '../../Store/Redux/users/types';
// type guards
// import { isAccessControlName } from '../../Store/Redux/roles/types';
/**
 * Access Control Guard
 * the controller is returned only if the user has all the acl and permissions passed by props
 */
const AccessControlGuard: FCWithChildren<AccessControlGuardProps> = props => {
  /*
   * local state
   */
  const authenticationUser = useSelector<RootState, User | null | undefined>(
    state => state.authentication.authenticationUser
  );
  /*
   * useEffects
   */
  /*
   * render
   */
  if (authenticationUser) {
    // if (authenticationUser.isSuperUser) {
    //   return props.controller;
    // } else
    // if user has access control
    if (authenticationUser.accessControl) {
      let allowed = true; // allowed variable
      for (let aclElement of props.acl) {
        // scan all acl elements
        const acl = authenticationUser.accessControl[aclElement]; // get the acl
        if (acl === undefined) {
          // if doesn't exists then access shall not be allowed
          allowed = false;
        } else {
          // if exists then check if there are any permissions
          if (props.permissions) {
            // if there are then scan all permissions and check if they don't exist or they are false
            for (let permission of props.permissions) {
              if (!acl[permission]) {
                // if the permission doesn't exist or is set to false then access shall not be allowed
                allowed = false;
              }
            }
          }
        }
      }
      if (allowed) return <PrivacyTermsGuard controller={props.controller} />;
    }
  }
  return <NotFoundController />;
  // return <Navigate to="/signIn" replace={true} />;
};
/*
 * export
 */
export default AccessControlGuard;
