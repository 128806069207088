/*
 * imports
 */
// import { Navigate } from 'react-router-dom';
// pages imports
// controllers
import NotFoundController from '../../Controllers/NotFoundController';
// guards
import PrivacyTermsGuard from '../PrivacyTermsGuard';
// redux imports
import { useSelector } from 'react-redux';
// types
import type { FCWithChildren } from '../../types';
import type { AdminGuardProps } from './types';
import type { RootState } from '../../Store/Redux/store.types';
import type { User } from '../../Store/Redux/users/types';
/**
 * Component
 */
const AdminGuard: FCWithChildren<AdminGuardProps> = props => {
  /*
   * local state
   */
  const authenticationUser = useSelector<RootState, User | null | undefined>(
    state => state.authentication.authenticationUser
  );
  /*
   * useEffects
   */
  /*
   * render
   */
  if (authenticationUser) {
    if (authenticationUser.isSuperUser) {
      return <PrivacyTermsGuard controller={props.controller} />;
    }
    // return <NotFoundController />;
  }
  return <NotFoundController />;
  // return <Navigate to="/signIn" replace={true} />;
};
/*
 * export
 */
export default AdminGuard;
