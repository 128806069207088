/*
 * imports
 */
import './styles.css';
import {} from 'react';
import { upperCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
// redux
import { useSelector } from 'react-redux';
// components - pages
import IBTLoadingSpinner from '../../Components/IBTLoadingSpinner';
import SaaSPage from '../../Components/SaaSPage';
import PublicPage from '../../Components/PublicPage';
// external libraries
import { t } from 'i18next';
import { Button } from '@blueprintjs/core';
// types
import type { FCWithChildren } from '../../../types';
import type { RootState } from '../../../Store/Redux/store.types';
import type { SystemConfiguration } from '../../../Store/Redux/system/types';
import type { User } from '../../../Store/Redux/users/types';
/**
 * Home Page
 */
const HomePage: FCWithChildren = () => {
  /*
   * Redux store
   */
  const authenticationUser = useSelector<RootState, User | null | undefined>(
    state => state.authentication.authenticationUser
  );
  const systemConfiguration = useSelector<RootState, SystemConfiguration>(
    state => state.system.systemConfiguration
  );
  /*
   * local state
   */
  const navigate = useNavigate();
  /*
   * handlers
   */
  const badgeWritersClickHandler = () => {
    navigate('/badgeWriters');
  };
  const signUpClickHandler = () => {
    navigate('/signUp');
  };
  const signInClickHandler = () => {
    navigate('/signIn');
  };
  /*
   * page content
   */
  let homeContent = (
    <main className="homepage-centered">
      <div>
        <IBTLoadingSpinner message={t('app.name') + ' HOMEPAGE'} noAnimate={true} />
      </div>
      <br />
      <div className="homepage-buttons">
        {authenticationUser?.accessControl?.badgeWriters && (
          <Button text="Go to badge writer" onClick={badgeWritersClickHandler} large={true} />
        )}
        {!authenticationUser && (
          <>
            <br />
            <Button
              intent="none"
              onClick={signInClickHandler}
              text={upperCase(t('signIn.label'))}
              large={true}
            />
            <br />
          </>
        )}
        {systemConfiguration?.signUp && (!authenticationUser || authenticationUser.isAnonymous) && (
          <Button
            intent="primary"
            onClick={signUpClickHandler}
            text={upperCase(t('signUp.label'))}
            large={true}
          />
        )}
      </div>
    </main>
  );
  /*
   * render
   */
  if (authenticationUser) {
    return <SaaSPage layout={{ withScrollableContent: true }}>{homeContent}</SaaSPage>;
  }
  return <PublicPage layout={{ withScrollableContent: true }}>{homeContent}</PublicPage>;
};
/*
 * export
 */
export default HomePage;
