/*
 * imports
 */
import { createSlice } from '@reduxjs/toolkit';
// types
import type { Payload } from '../store.types';
import type { TaskSetupState, TaskSetup } from './types';
/**
 * initial state
 */
const taskSetupInitialState: TaskSetupState = {
  taskSetup: {},
  taskSetupMillis: 0,
};
/**
 * taskSetup slice
 */
const taskSetupSlice = createSlice({
  name: 'taskSetup',
  initialState: taskSetupInitialState,
  reducers: {
    taskSetupMerge(
      state: TaskSetupState,
      action: Payload<{ taskSetup: TaskSetup; millis: number }>
    ) {
      const { taskSetup, millis } = action.payload;
      state.taskSetup = taskSetup;
      state.taskSetupMillis = millis;
    },
  },
});
/*
 * exports
 */
export default taskSetupSlice.reducer;
export const taskSetupActions = taskSetupSlice.actions;
