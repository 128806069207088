/*
 * imports
 */
import { useEffect, useContext } from 'react';
import { ToasterContext } from './Store/Context/Toaster';
// router imports
// redux imports
import { useDispatch } from 'react-redux';
import { systemSetup } from './Store/Redux/system/actions';
import { usersDetachFirestoreListener } from './Store/Redux/users/actions';
import { authenticationCleanup, authenticationSetup } from './Store/Redux/authentication/actions';
import { rolesDetachUnsubscribe } from './Store/Redux/roles/actions';
import { badgeWritersDetachUnsubscribe } from './Store/Redux/badgeWriters/actions';
// types
import type { FCWithChildren } from './types';
import type { AppDispatch } from './Store/Redux/store.types';
/*
 *
 *
import logger from '@infobiotech/js-logger';
const componentName = 'Bootstrapper';
logger.setLogLevel(logger.INFO, componentName);
/**
 * Bootstrapper component
 */
const Bootstrapper: FCWithChildren = props => {
  /*
   * local state
   */
  // logger.info(componentName);
  const toasterCtx = useContext(ToasterContext);
  /*
   * redux
   */
  const dispatch = useDispatch<AppDispatch>();
  /*
   * local state
   */
  /*
   * component did mount && unmount
   */
  useEffect(() => {
    // TODO: an unique dispatch "init"
    dispatch(systemSetup(toasterCtx));
    dispatch(authenticationSetup(toasterCtx));
    return () => {
      authenticationCleanup();
      usersDetachFirestoreListener();
      rolesDetachUnsubscribe();
      badgeWritersDetachUnsubscribe();
    };
  }, []);
  /*
   * render
   */
  return <>{props.children}</>;
};
/*
 * export
 */
export default Bootstrapper;
