/*
 * imports
 */
import ErrorPage from '../../UI/Pages/ErrorPage';
// types
import type { FCWithChildren } from '../../types';
/* *
import logger from '@infobiotech/js-logger';
const componentName = 'ErrorController';
logger.setLogLevel(logger.INFO, componentName);
/* */
/**
 * Error Page Controller
 */
const ErrorController: FCWithChildren = () => {
  return <ErrorPage />;
};
/*
 * export
 */
export default ErrorController;
