/*
 * imports
 */
import usePath from '../../Hooks/use-path';
import { Navigate, useParams } from 'react-router-dom';
// page imports
// redux imports
import { useSelector } from 'react-redux';
// guards
import PrivacyTermsGuard from '../PrivacyTermsGuard';
// types
import type { FCWithChildren } from '../../types';
import type { SignedUpGuardProps } from './types';
import type { RootState } from '../../Store/Redux/store.types';
import type { User } from '../../Store/Redux/users/types';
/**
 * Component
 */
const SignedUpGuard: FCWithChildren<SignedUpGuardProps> = props => {
  /*
   * local state
   */
  const path = usePath().path;
  const { edit } = useParams();
  const user = useSelector<RootState, User | null | undefined>(
    state => state.authentication.authenticationUser
  );
  /*
   * useEffects
   */
  /*
   * render
   */
  if (user) {
    if (user.isAnonymous) {
      localStorage.setItem('continuePath', path);
      return <Navigate to="/signUp" replace={true} />;
    }
    localStorage.removeItem('continuePath');
    return (
      <PrivacyTermsGuard
        isPrivacyTerms={edit === 'privacy' || edit === 'terms'}
        controller={props.controller}
      />
    );
  }
  localStorage.setItem('continuePath', path);
  return <Navigate to="/signIn" replace={true} />;
};
/*
 * export
 */
export default SignedUpGuard;
