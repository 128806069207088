const firebase = {
  emulators: {
    auth: false,
    firestore: false,
    functions: false,
  },
  functions: {
    region: 'europe-west1',
  },
  remoteConfig: {
    minimumFetchIntervalMinutes: window.location.hostname === 'localhost' ? 3 : 60 * 12,
    default: {
      superUserEmailAddresses: ['a.raffa@infobiotech.com', 'r.sarro@infobiotech.com'],
      privacyMillis: 1234567890,
      termsMillis: 1234567890,
    },
  },
};
const production = window.location.hostname === 'localhost' ? false : true;
const baseUrl =
  window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://console.icgene.com';
const devUrl = 'http://localhost:3000';
const stagingUrl = 'https://staging.console.icgene.com';
const controllers = {
  default: ['home', null, ''],
  signIn: 'signIn',
  public: [
    'home',
    'about',
    'signUp',
    'passwordRecovery',
    'notFound',
    'error',
    'userSupport',
    'contribute',
    'privacy',
    'terms',
  ],
  unauthorized: 'unauthorized',
  signedIn: ['dashboard', 'userBadge', 'user', 'settings', 'signOut', 'reAuth'], // + unauthorized
  superUser: ['users', 'system'], // + signedIn
  defaultAfterSignIn: 'home',
  defaultAfterSignOut: 'home',
};
const logLevel = 4; // info
/*
 * exports
 */
export default {
  firebase,
  production,
  baseUrl,
  devUrl,
  stagingUrl,
  controllers,
  logLevel,
};
