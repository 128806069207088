/*
 * imports
 */
// page imports
import Page from '../Page';
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';
// types
import type { FCWithChildren } from '../../../types';
import type { PageProps as PublicPageProps } from '../Page/types';
/**
 * Public Page Component
 */
const PublicPage: FCWithChildren<PublicPageProps> = props => {
  const { header, footer, layout, ...restProps } = props;
  const pageLayout = { ...layout, fullWidth: true };
  return (
    <Page header={header || <PageHeader />} footer={footer || <PageFooter />} layout={pageLayout}>
      {restProps?.children || null}
    </Page>
  );
};
/*
 * export
 */
export default PublicPage;
