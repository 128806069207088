/*
 * imports
 */
import { rolesActions } from './slice';
import { firestore, firestoreCollection, firestoreOnSnapshot } from '../../../Vendors/firebase';
// external libraries
// types
import type { RootState } from '../store.types';
import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';
import type { FirestoreUnsubscribe } from '../../../Vendors/firebase/types';
import type { ToasterContextObj } from '../../Context/Toaster/types';
import type { RolesState, RoleName } from './types';
// type guards
import { isRoleName } from './types';
// global
let rolesUnsubscribe: FirestoreUnsubscribe | null = null;
export const rolesDetachUnsubscribe = () => {
  if (rolesUnsubscribe) {
    rolesUnsubscribe();
    rolesUnsubscribe = null;
  }
};
/**
 * Roles setup
 */
export const rolesSetup = ({
  firestoreErrorHandler,
}: ToasterContextObj): ThunkAction<void, RootState, any, AnyAction> => {
  return async (dispatch, getState) => {
    rolesDetachUnsubscribe();
    rolesUnsubscribe = firestoreOnSnapshot(
      firestoreCollection(firestore, 'roles'),
      snapshot => {
        let roles = getState().roles;
        snapshot.docChanges().forEach(change => {
          const document = change.doc.data();
          const roleId = change.doc.id;
          // if it's not a md5 just ignore it
          if (isRoleName(roleId)) {
            if (change.type === 'added') {
              roles = {
                ...roles,
                [roleId]: {
                  ...document,
                  accessControl: document.accessControl,
                  enabled: document.enabled,
                },
              };
            } else if (change.type === 'modified') {
              roles = {
                ...roles,
                [roleId]: {
                  ...document,
                  accessControl: document.accessControl,
                  enabled: document.enabled,
                },
              };
            } else if (change.type === 'removed') {
              dispatch(_unsetRole(roleId));
            }
          }
        });
        if (Object.keys(roles).length) {
          dispatch(_setRoles(roles));
        }
      },
      firestoreErrorHandler({
        cleanupFn: () => {
          rolesDetachUnsubscribe();
          // @todo force a reload ?
        },
      })
    );
  };
};
/*
 * action creator
 */
const _setRoles = (roles: RolesState) => {
  return rolesActions.setRoles({ roles });
};
const _unsetRole = (roleName: RoleName) => {
  return rolesActions.deleteRole({ roleName });
};
/** */
