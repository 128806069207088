/*
 * imports
 */
import { createSlice } from '@reduxjs/toolkit';
// external libraries
import sha256 from 'crypto-js/sha256';
// types
import type { SecurityState } from './types';
/**
 * initial state
 */
const securityInitialState: SecurityState = {
  securityRecaptchaMillis:
    localStorage.getItem('recaptcha2') === sha256(window.navigator.userAgent).toString()
      ? Date.now()
      : 0,
};
/**
 * security slice
 */
const securitySlice = createSlice({
  name: 'security',
  initialState: securityInitialState,
  reducers: {
    setRecaptcha(state: SecurityState) {
      state.securityRecaptchaMillis =
        localStorage.getItem('recaptcha2') === sha256(window.navigator.userAgent).toString()
          ? Date.now()
          : 0;
    },
  },
});
/*
 * exports
 */
export default securitySlice.reducer;
export const securityActions = securitySlice.actions;
