/*
 * imports
 */
import { memo, Fragment } from 'react';
// router imports
import { useNavigate } from 'react-router-dom';
import usePath from '../../../Hooks/use-path';
// react-icons import
// import { FaHandshake } from 'react-icons/fa';
// redux imports
import { useSelector } from 'react-redux';
/* i18next import *
import { useTranslation, Trans } from 'react-i18next';
/* blueprintjs imports */
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
// other components
import MenuItems from '../MenuItems';
// types
import type { FCWithChildren } from '../../../types';
import type { RootState } from '../../../Store/Redux/store.types';
import type { User, Users } from '../../../Store/Redux/users/types';
import type { Navigation } from './types';
import type { BadgeWriters } from '../../../Store/Redux/badgeWriters/types';
/**
 * SaasPage Navigation Component
 */
const SaaSPageNavigation: FCWithChildren = memo(() => {
  /*
   * Redux store
   */
  const authenticationUser = useSelector<RootState, User | null | undefined>(
    state => state.authentication.authenticationUser
  );
  const users = useSelector<RootState, Users>(state => state.users.users);
  const badgeWriters = useSelector<RootState, BadgeWriters>(
    state => state.badgeWriters.badgeWriters
  );
  /*
   * local state
   */
  const { controller: currController, entityPath } = usePath();
  const navigate = useNavigate();
  /* *
  const { t } = useTranslation();
  /* */
  const navigation: Navigation = {
    /* *
    services: {
      label: 'Services',
      level: 'L0',
      items: [
        {
          path: 'business-identity',
          label: t('labels.businessIdentity'),
          status: 'warning',
          icon: 'office',
          info: {
            type: 'badge',
            text: '0%',
            tooltip: {
              status: 'warning',
              text: (
                <Trans i18nKey="businessIdentity.warningMessage1">
                  You need to setup your business identity
                </Trans>
              ),
            },
          },
        },
        {
          path: 'resources',
          label: t('labels.resources'),
          status: 'danger',
          icon: 'grid-view',
          info: {
            type: 'icon',
            icon: 'warning-sign',
            tooltip: {
              status: 'danger',
              text: (
                <Trans i18nKey="resources.warningMessage1">
                  Exhausted resources are preventing some activities from running
                </Trans>
              ),
            },
          },
        },
        {
          path: 'freelance-platform',
          label: t('labels.freelancePlatform'),
          icon: 'exchange',
        },
        {
          path: 'projects',
          label: t('labels.projects'),
          icon: 'projects',
          info: {
            type: 'icon',
            icon: 'dot',
          },
        },
        {
          path: 'finance',
          label: t('labels.finance'),
          disabled: true,
          icon: 'dollar',
          info: {
            type: 'icon',
            icon: 'info-sign',
            tooltip: {
              text: (
                <Trans i18nKey="finance.warningMessage1">
                  Finance section will be available from business level 4
                </Trans>
              ),
            },
          },
        },
        {
          path: 'employees',
          label: t('labels.employees'),
          disabled: true,
          icon: 'people',
          info: {
            type: 'icon',
            icon: 'info-sign',
            tooltip: {
              text: (
                <Trans i18nKey="employees.warningMessage1">
                  Employees section will be available from business level 8
                </Trans>
              ),
            },
          },
        },
        {
          path: 'headquarter',
          label: t('labels.headquarter'),
          icon: 'office',
        },
        /*
         *
         *
        {
          path: 'messages',
          label: t('labels.messages'),
          status: 'warning',
          icon: 'envelope',
          info: {
            type: 'badge',
            text: '1',
          },
        },
        {
          // disabled: true,
          path: 'skills',
          label: t('labels.skills'),
          status: null,
          icon: 'list-columns',
          info: {
            type: 'badge',
            text: '0',
            tooltip: null,
          },
        },
        {
          path: 'inventory',
          label: t('labels.inventory'),
          status: null,
          icon: 'properties',
          info: {
            type: 'badge',
            text: '3',
            tooltip: null,
          },
        },
        {
          path: 'playerStore',
          label: t('labels.playerStore'),
          status: null,
          icon: 'shopping-cart',
          info: null,
        },
        {
          path: 'elearning-platform',
          label: t('labels.eLearningPlatform'),
          icon: 'learning',
        },
        {
          path: 'network',
          label: t('labels.network'),
          disabled: true,
          icon: 'diagram-tree',
          info: {
            type: 'icon',
            icon: 'info-sign',
            tooltip: {
              text: (
                <Trans i18nKey="network.warningMessage1">
                  Before you can join or create a network, you need to develop the required skills
                </Trans>
              ),
            },
          },
        },
        {
          path: 'hacking',
          label: t('labels.hacking'),
          disabled: true,
          icon: 'ninja',
          info: {
            type: 'icon',
            icon: 'info-sign',
            tooltip: {
              text: (
                <Trans i18nKey="hacking.warningMessage1">
                  Before you can perform hacking activities, you need to develop the required skills
                </Trans>
              ),
            },
          },
        },
        {
          path: 'predictive-analysis',
          label: t('labels.predictiveAnalysis'),
          disabled: true,
          icon: 'predictive-analysis',
          info: {
            type: 'icon',
            icon: 'info-sign',
            tooltip: {
              text: (
                <Trans i18nKey="predictiveAnalysis.warningMessage1">
                  Predictive analysis features will be available as soon as you develop the required
                  skills
                </Trans>
              ),
            },
          },
        },
      ],
    },
  /* */
  };
  if (authenticationUser?.isSuperUser) {
    navigation.superUser = {
      label: 'Admin',
      items: [
        {
          path: 'system',
          label: 'System',
          icon: 'cog',
        },
        {
          path: 'users',
          label: 'Users',
          icon: 'people',
          info: {
            type: 'badge',
            text: `${Object.keys(users || {}).length}`,
          },
        },
        {
          path: 'devices',
          label: 'Devices',
          icon: 'projects',
          info: {
            type: 'badge',
            text: `${Object.keys(badgeWriters || {}).length}`,
          },
        },
        /** *
        {
          disabled: true,
          path: 'customers',
          label: 'Customers',
          icon: (
            <FaHandshake
              style={{
                marginTop: 3,
                color: '#5c7080',
              }}
            />
          ),
        },
        /** */
      ],
    };
  }
  /** *
  navigation.manufacturing = {
    label: 'Manufacturing',
    items: [
      {
        disabled: false,
        path: 'devices',
        label: 'Devices',
        icon: 'list-columns',
        status: 'warning',
        info: {
          type: 'icon',
          icon: 'info-sign',
          tooltip: {
            text: 'There is a problem with one of your badgeWriters',
          },
        },
      },
      {
        disabled: false,
        path: 'kits',
        label: 'Kits',
        icon: 'label',
        info: {
          type: 'badge',
          text: '19',
        },
      },
    ],
  };
  /** */
  const itemClickHandler = (controller: string) => {
    return () => {
      if (currController !== controller || (currController === controller && entityPath)) {
        navigate(`/${controller}`);
      }
    };
  };
  let navigationItems: (JSX.Element | null)[] | null = null;
  if (navigation) {
    const menuItemAction = (controller: string) => {
      if (currController !== controller || (currController === controller && entityPath)) {
        navigate(`/${controller}`);
      }
    };
    navigationItems = Object.keys(navigation)
      .map((sectionKey, sectionIndex) => {
        const section = navigation[sectionKey];
        if (section?.items?.length) {
          return (
            <Fragment key={`section${sectionIndex}`}>
              <MenuDivider title={`${section.label}`} />
              <MenuItems
                items={section.items}
                currController={currController}
                action={menuItemAction}
              />
            </Fragment>
          );
        } else {
          return null;
        }
      })
      .filter(section => section !== null);
  }
  /*
   * render
   */
  return (
    <Menu className="p-0">
      {authenticationUser?.accessControl?.badgeWriters && (
        <MenuItem
          icon="dashboard"
          text="Badge Writer"
          active={currController === 'badgeWriters' && !entityPath}
          onClick={itemClickHandler('badgeWriters')}
        />
      )}
      {navigationItems}
    </Menu>
  );
});
/*
 * export
 */
export default SaaSPageNavigation;
