/*
 * imports
 */
// blueprintjs imports
import { Icon, MenuItem } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
// types
import type { FCWithChildren } from '../../../types';
import type { MenuItemsProps, MenuItemProps } from './types';
/**
 * MenuItems Component
 */
const MenuItems: FCWithChildren<MenuItemsProps> = ({ items, currController, action }) => {
  /*
   * local state
   */
  let content: JSX.Element | null = null;
  if (items.length) {
    const menuItemElements = items.map((menuItem, menuItemIndex) => {
      const disabled = menuItem.disabled === true;
      const active = !disabled && currController === menuItem.path;
      const intent = menuItem.status;
      const labelElementInner = menuItem?.info?.type ? (
        menuItem?.info?.type === 'badge' ? (
          <span className={`bp3-tag bp3-${intent ? 'intent-' + intent : 'minimal'}`}>
            {menuItem?.info?.text}
          </span>
        ) : menuItem?.info?.type === 'icon' ? (
          <Icon icon={menuItem?.info?.icon || null} />
        ) : null
      ) : null;
      const tooltip = menuItem.info?.tooltip;
      // eslint-disable-next-line no-undef
      const labelElement: JSX.Element | null =
        labelElementInner && tooltip ? (
          <Tooltip2
            usePortal={true}
            intent={tooltip.status}
            minimal={true}
            placement={'right'}
            content={<span>{tooltip.text}</span>}
          >
            {labelElementInner}
          </Tooltip2>
        ) : (
          labelElementInner
        );
      const menuItemProps: MenuItemProps = {
        disabled,
        active,
        icon: menuItem.icon,
        text: menuItem.label || '',
        intent,
        onClick: () => {
          if (!active) {
            action(menuItem?.path);
          }
        },
        labelElement,
      };
      return <MenuItem key={`menuItem${menuItemIndex}`} {...menuItemProps} />;
    });
    content = <>{menuItemElements}</>;
  }
  /*
   * render
   */
  return content;
};
/*
 * export
 */
export default MenuItems;
