/*
 * imports
 */
// pages
import PublicPage from '../../Components/PublicPage';
// types
import type { FCWithChildren } from '../../../types';
/**
 * About Page
 */
const AboutPage: FCWithChildren = () => {
  return (
    <PublicPage layout={{ withPadding: true }}>
      <h1>About us</h1>
    </PublicPage>
  );
};
/*
 * export
 */
export default AboutPage;
