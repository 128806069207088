/*
 * imports
 */
import configModule from '../../../config.module';
import { api } from '../../../Vendors/api';
// redux
import { authenticationActions } from './slice';
import { usersDeleteUser, usersDetachFirestoreListener } from '../users/actions';
import { badgeWritersDetachUnsubscribe } from '../badgeWriters/actions';
import { rolesDetachUnsubscribe } from '../roles/actions';
// firebase imports
import {
  getAdminAuthenticationErrorMessage,
  linkAccountErrorHandler,
} from '../../../Vendors/firebase/error';
import {
  // firebase
  firebaseAuth,
  firebaseAuthIsSignInWithEmailLink,
  firebaseAuthOnAuthStateChanged,
  firebaseAuthSendSignInLinkToEmail,
  firebaseAuthSignInWithEmailAndPassword,
  firebaseAuthSignInWithEmailLink,
  firebaseAuthSignInWithPopup,
  firebaseAuthSignOut,
  firebaseFunctions,
  firebaseFunctionsHttpsCallable,
  firebaseAuthCreateUserWithEmailAndPassword,
  firebaseAuthSignInAnonymously,
  firebaseLinkAccount,
  firebaseUpdatePassword,
  // firestore
  firestore,
  firestoreOnSnapshot,
  firestoreDoc,
  firestoreUpdateEmail,
  GoogleAuthProvider,
} from '../../../Vendors/firebase';
// types
import type { AppDispatch, RootState, UtilFunctions } from '../store.types';
import type { AuthenticationI18n, SignInData, SignUpData } from './types';
import type { User, UserArgs } from '../users/types';
import type {
  FirestoreUnsubscribe,
  FirebaseHttpsCallableResult,
  FirestoreDocumentSnapshot,
} from '../../../Vendors/firebase/types';
import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';
import type { FirebaseError } from 'firebase/app';
import type { AuthError, UserCredential } from 'firebase/auth';
import type { ProfileTabEdit } from '../../../UI/Pages/SettingsPage/reducer';
import type { ToastArgs, ToasterContextObj } from '../../Context/Toaster/types';
import type { HttpResponse } from '../../../Classes/IbtHttp/types';
/*
 *
 *
import logger from '@infobiotech/js-logger';
const componentName = 'authentication';
logger.setLogLevel(logger.INFO, componentName);
/*
 *  init
 */
let onAuthStateChangeUnsubscribe: FirestoreUnsubscribe;
let onUserSnapshotUnsubscribe: FirestoreUnsubscribe | null = null;
const detachOnUserSnapshotUnsubscribe = () => {
  if (onUserSnapshotUnsubscribe) {
    onUserSnapshotUnsubscribe();
    onUserSnapshotUnsubscribe = null;
  }
};
/**
 * authentication setup
 */
export const authenticationSetup = (
  toasterCtx: ToasterContextObj
): ThunkAction<void, RootState, any, AnyAction> => {
  const { makeSuccessToast, makeErrorToast, firebaseErrorHandler, firestoreErrorHandler } =
    toasterCtx;
  return async (dispatch, getState) => {
    // console.log('authenticationSetup');
    /* *
    logger.info(componentName, 'authenticationSetup');
    /* */
    // Confirm the link is a sign-in with email link.
    if (firebaseAuthIsSignInWithEmailLink(firebaseAuth, window.location.href)) {
      // console.log('isSignInWithEmailLink');
      /* *
      logger.info(componentName, 'isSignInWithEmailLink');
      /* */
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      if (email) {
        dispatch(_toggleSigningIn(true));
        // The client SDK will parse the code from the link for you.
        firebaseAuthSignInWithEmailLink(firebaseAuth, email, window.location.href)
          .then(() => {
            makeSuccessToast({ message: 'Logged in successfully' });
            dispatch(_toggleSigningIn(false));
            /* *
            logger.info(componentName, 'signInWithEmailLink.then', { result });
            /* */
            // Clear email from storage.
            localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch(
            firebaseErrorHandler({
              message: (error: FirebaseError) => {
                if (error.code === 'auth/expired-action-code') {
                  // OTP in email link expired
                }
                if (error.code === 'auth/invalid-email') {
                  // Email address is not valid
                }
                if (error.code === 'auth/user-disabled') {
                  // User corresponding to the given email has been disabled
                }
                return error.message;
              },
              cleanupFn: () => {
                dispatch(_toggleSigningIn(false));
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
                /* *
              logger.error(componentName, 'signInWithEmailLink', 'catch', {
                code: error?.code || null,
                name: error?.name || null,
                message: error?.message || null,
                error,
              });
              /* */
              },
            })
          );
      }
    }
    authenticationCleanup();
    onAuthStateChangeUnsubscribe = firebaseAuthOnAuthStateChanged(
      firebaseAuth,
      async nextUser => {
        // console.log('OnAuthStateChanged', { nextUser });
        const { authenticationUser } = getState().authentication;
        const authStatus = authenticationUser
          ? nextUser
            ? 'switched'
            : 'signedOut'
          : nextUser
          ? 'signedIn'
          : 'public';
        /* *
      logger.info(componentName, 'onAuthStateChanged', {
        authStatus,
        authenticationUser,
        nextUser,
      });
      /* */
        // console.log('switch authStatus', { authStatus });
        switch (authStatus) {
          case 'switched':
          case 'signedIn': {
            /**
             * try to update user of `userId`, `threshold` times
             * @param userId the user's id to update
             * @param threshold the number of times you are willing to try
             */
            const repeatUpdateUser = async (
              threshold: number,
              userId: string
            ): Promise<FirebaseHttpsCallableResult<any> | null> => {
              // ): Promise<HttpResponse | null> => {
              // console.log('repeatUpdateUser', { threshold, userId });
              if (threshold < 1) {
                throw new Error("Could not retrieve user's data");
              }
              try {
                /** firebase callables start */
                let response: FirebaseHttpsCallableResult<any> | null = null;
                response = await firebaseFunctionsHttpsCallable<
                  // done
                  { userId: string | undefined; lastAuthenticationMillis: number },
                  any
                >(
                  firebaseFunctions,
                  'userUpdate'
                )({
                  userId,
                  lastAuthenticationMillis: Date.now(),
                });
                // console.log('responseFirebaseCallableUserUpdate', { response });
                if (response.data.error) {
                  return null;
                }
                return response;
                /** firebase callables end */
                /** new api http request start *
                let responseAPI = await api.patch(
                  `/users/${userId}/authentications`
                );
                if (responseAPI.status >= 400) {
                  return null;
                }
                return responseAPI;
                /** new api http request end */
              } catch {
                // console.log('error while getting user data. retrying...');
                throw new Error('Error while getting user data. Retrying...');
              }
            };
            const waitUpdateUser = (
              // eslint-disable-next-line no-unused-vars
              afterResponse: (updateUserResponse: FirebaseHttpsCallableResult<any>) => void,
              userId: string,
              times: number,
              waitTime = 2000,
              wait = true
            ) => {
              // console.log('waitUpdateUser', { afterResponse, userId, times, waitTime, wait });
              if (!wait) {
                // console.log(
                //   'waitUpdateUser - calling repeatUpdateUser(' + times + ', ' + userId + ')'
                // );
                repeatUpdateUser(times, userId)
                  .then(result => {
                    // console.log('waitUpdateUSer - repeatUpdateUser.then', { result });
                    if (result) {
                      afterResponse(result);
                    } else {
                      waitUpdateUser(afterResponse, userId, times - 1, waitTime);
                    }
                  })
                  .catch(error => {
                    // console.log('waitUpdateUser - repeatUpdateUser.catch', { error });
                    makeErrorToast({ message: error.message });
                    waitUpdateUser(afterResponse, userId, times - 1, waitTime);
                  });
              } else if (times > 0) {
                // console.log('times > 0 - setTimeout');
                setTimeout(() => {
                  // console.log('waitUpdateUser - after setTimeout - calling waitUpdateUser');
                  waitUpdateUser(afterResponse, userId, times, waitTime, false);
                }, waitTime);
              } else {
                makeErrorToast({ message: "Could not retrieve user's data" });
              }
            };
            const afterResponse = (updateUserResponse: FirebaseHttpsCallableResult<any>) => {
              detachOnUserSnapshotUnsubscribe();
              if (updateUserResponse?.data?.error || updateUserResponse?.data !== true) {
                dispatch(authenticationSignOut(toasterCtx, {}));
                const systemConfiguration = getState().system.systemConfiguration;
                if (!systemConfiguration.signUp) {
                  /* *
                    logger.error(componentName, 'onAuthStateChanged', 'Unauthorized');
                  /* */
                  makeErrorToast({ message: `code: 001 - Unauthorized` });
                } else {
                  /* *
                    logger.error(componentName, 'onAuthStateChanged', 'not found');
                  /* */
                  makeErrorToast({ message: `Error occurred. User not found` });
                }
                dispatch(_toggleSigningIn(false));
              } else {
                const lastLoginAt = new Date(nextUser?.metadata.lastSignInTime || '').getTime();
                onUserSnapshotUnsubscribe = firestoreOnSnapshot<any>(
                  firestoreDoc(firestore, 'users', nextUser?.uid || ''),
                  (documentSnapshot: FirestoreDocumentSnapshot<User>) => {
                    /* *
                  logger.info(componentName, 'onUserSnapshotUnsubscribe');
                  /* */
                    if (documentSnapshot?.exists()) {
                      const user = documentSnapshot.data();
                      dispatch(
                        _setUser({
                          ...user,
                          lastLoginAt,
                        })
                      );
                      if (getState().authentication.authenticationSigningIn) {
                        dispatch(_toggleSigningIn(false));
                      }
                    } else {
                      // makeErrorToast({ message: 'User does not exists' });
                      dispatch(_setUser(undefined));
                      dispatch(_toggleSigningIn(false));
                      detachOnUserSnapshotUnsubscribe();
                    }
                  },
                  firestoreErrorHandler({
                    cleanupFn: () => {
                      /* *
                        logger.error(componentName, 'onUserSnapshotUnsubscribe', {
                          code: snapshotError?.code,
                          error: snapshotError,
                          message: snapshotError?.message,
                          name: snapshotError?.name,
                        });
                      /* */
                      detachOnUserSnapshotUnsubscribe();
                      dispatch(_setUser(undefined));
                      dispatch(_toggleSigningIn(false));
                    },
                  })
                );
              }
            };
            /** end afterResponse function */
            try {
              // dispatch(_toggleSigningIn(true)); // already done
              const providers = nextUser!.providerData.map(provider => provider.providerId);
              dispatch(_setProviderData(providers));
              const userId = nextUser!.uid;
              if (userId) {
                waitUpdateUser(afterResponse, userId, 5, 3000, false);
              } else {
                throw new Error('User does not have an ID');
              }
              /* *
            logger.info(componentName, 'onAuthStateChanged', {
              authStatus,
              updateUserResponse,
              userId: nextUser?.uid,
            });
            /* */
            } catch (error) {
              makeErrorToast({ message: "Error while updating user's data" });
              dispatch(_toggleSigningIn(false));
              /* *
            logger.error(componentName, 'onAuthStateChanged', {
              authStatus,
              code: error?.code || null,
              name: error?.name || null,
              message: error?.message || null,
              error,
            });
            /* */
            }
            break;
          }
          case 'public':
          case 'signedOut':
          default: {
            // console.log('case: Public / signedOut / default');
            detachOnUserSnapshotUnsubscribe();
            dispatch(_setUser(undefined));
            break;
          }
        }
      },
      // @ts-ignore
      (error: AuthError) => {
        if (error.code === 'auth/app-deleted') {
          // Thrown if the instance of FirebaseApp has been deleted.
        } else if (error.code === 'auth/app-not-authorized') {
          // Thrown if the app identified by the domain where it's hosted,
          // is not authorized to use Firebase Authentication with the provided API key.
          // Review your key configuration in the Google API console.
        } else if (error.code === 'auth/argument-error') {
          // Thrown if a method is called with incorrect arguments.
        } else if (error.code === 'auth/invalid-api-key') {
          // Thrown if the provided API key is invalid. Please check that you have copied it correctly from the Firebase Console.
        } else if (error.code === 'auth/invalid-user-token') {
          // Thrown if the user's credential is no longer valid. The user must sign in again.
        } else if (error.code === 'auth/invalid-tenant-id') {
          // Thrown if the tenant ID provided is invalid.
        } else if (error.code === 'auth/network-request-failed') {
          // Thrown if a network error (such as timeout, interrupted connection or unreachable host) has occurred.
        } else if (error.code === 'auth/operation-not-allowed') {
          // Thrown if you have not enabled the provider in the Firebase Console.
          // Go to the Firebase Console for your project, in the Auth section and the Sign in Method tab and configure the provider.
        } else if (error.code === 'auth/requires-recent-login') {
          // Thrown if the user's last sign-in time does not meet the security threshold.
          // Use firebase.User.reauthenticateWithCredential to resolve. This does not apply if the user is anonymous.
        } else if (error.code === 'auth/too-many-requests') {
          // Thrown if requests are blocked from a device due to unusual activity. Trying again after some delay would unblock.
        } else if (error.code === 'auth/unauthorized-domain') {
          // Thrown if the app domain is not authorized for OAuth operations for your Firebase project.
          // Edit the list of authorized domains from the Firebase console.
        } else if (error.code === 'auth/user-disabled') {
          // Thrown if the user account has been disabled by an administrator.
          // Accounts can be enabled or disabled in the Firebase Console, the Auth section and Users subsection.
        } else if (error.code === 'auth/user-token-expired') {
          // Thrown if the user's credential has expired. This could also be thrown if a user has been deleted.
          // Prompting the user to sign in again should resolve this for either case.
        } else if (error.code === 'auth/web-storage-unsupported') {
          // Thrown if the browser does not support web storage or if the user disables them.
        }
        makeErrorToast({ message: error.message });
      }
    );
  };
};
/*
 * sign in
 */
const accountCreatedHandler = (
  // eslint-disable-next-line no-unused-vars
  makeErrorToast: (toast?: ToastArgs | undefined) => void,
  // eslint-disable-next-line no-unused-vars
  makeSuccessToast: (toast?: ToastArgs | undefined) => void,
  dispatch: AppDispatch
) => {
  return (result: any) => {
    if (!result || result.data?.error || result.data !== true) {
      makeErrorToast({ message: result.data?.error || 'An error occurred while updating email' });
    } else {
      makeSuccessToast({ message: 'Account created.' });
      dispatch(_toggleSigningUp(false));
    }
  };
};
const updateUserInfo = ({ user }: UserCredential, dispatch: AppDispatch) => {
  const providers = user.providerData.map(provider => provider.providerId);
  dispatch(_setProviderData(providers));
  const newUser: UserArgs = {
    emailAddress: user.email,
    isAnonymous: user.isAnonymous,
  };
  dispatch(_updateUser(newUser));
};
/**
 * signing in
 */
export const authenticationSignIn = (
  { makeSuccessToast, firebaseErrorHandler, makeErrorToast }: ToasterContextObj,
  { emailAddress = null, password = null, provider = null }: SignInData
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (dispatch, getState) => {
    const currentUser = firebaseAuth.currentUser;
    if (currentUser) {
      if (currentUser.isAnonymous && provider) {
        dispatch(_toggleSigningUp(true));
        firebaseLinkAccount({ providerId: provider })
          .then((userCredential: UserCredential) => {
            updateUserInfo(userCredential, dispatch);
            return firestoreUpdateEmail(
              userCredential.user.email,
              getState().authentication.authenticationUser?.userId
            );
          })
          .then(accountCreatedHandler(makeErrorToast, makeSuccessToast, dispatch))
          .catch(
            firebaseErrorHandler({
              message: linkAccountErrorHandler,
              cleanupFn: () => {
                dispatch(_toggleSigningUp(false));
              },
            })
          );
      } else {
        makeErrorToast({ message: 'You are already logged-in. Cannot sign in again' });
      }
    } else if (emailAddress) {
      dispatch(_toggleSigningIn(true));
      const systemConfiguration = getState().system.systemConfiguration;
      if (password) {
        firebaseAuthSignInWithEmailAndPassword(firebaseAuth, emailAddress, password)
          .then(() => {
            makeSuccessToast({ message: 'Logged in successfully' });
          })
          .catch(
            firebaseErrorHandler({
              message: error => {
                if (error.code === 'auth/invalid-email') {
                  // Thrown if the email address is not valid.
                } else if (error.code === 'auth/user-disabled') {
                  // Thrown if the user corresponding to the given email has been disabled.
                } else if (error.code === 'auth/user-not-found') {
                  // Thrown if there is no user corresponding to the given email.
                } else if (error.code === 'auth/wrong-password') {
                  // Thrown if the password is invalid for the given email,
                  // or the account corresponding to the email does not have a password set.
                }
                return error.message;
              },
              cleanupFn: () => {
                /* *
                logger.error(
                  componentName,
                  'firebaseAuth.sendSignInLinkToEmail',
                  'catch error: ' + firebaseAuthError,
                  { code, message }
                );
                /*
                 * codes:
                 * auth/user-not-found
                 * auth/wrong-password
                 */
                dispatch(_toggleSigningIn(false));
                // dispatch(
                //   _setError({
                //     code,
                //     message,
                //   })
                // );
              },
            })
          );
      } else if (systemConfiguration?.passwordlessAuthentication === true) {
        let continuePath = localStorage.getItem('continuePath');
        if (!continuePath) {
          continuePath = '/' + configModule.controllers.defaultAfterSignIn;
        }
        firebaseAuthSendSignInLinkToEmail(firebaseAuth, emailAddress, {
          url: `${configModule.baseUrl}${continuePath}`,
          handleCodeInApp: true,
        })
          .then(() => {
            /* *
            logger.info(
              componentName,
              'authenticationSignIn.firebaseAuth.sendSignInLinkToEmail.then'
            );
            /* */
            localStorage.setItem('emailForSignIn', emailAddress);
            dispatch(_setSignInLinkToEmail(emailAddress));
            dispatch(_toggleSigningIn(false));
          })
          .catch(
            firebaseErrorHandler({
              message: error => {
                if (error.code === 'auth/argument-error') {
                  // Thrown if handleCodeInApp is false.
                } else if (error.code === 'auth/invalid-email') {
                  // Thrown if the email address is not valid.
                } else if (error.code === 'auth/missing-android-pkg-name') {
                  // An Android package name must be provided if the Android app is required to be installed.
                } else if (error.code === 'auth/missing-continue-uri') {
                  // A continue URL must be provided in the request.
                } else if (error.code === 'auth/missing-ios-bundle-id') {
                  // An iOS Bundle ID must be provided if an App Store ID is provided.
                } else if (error.code === 'auth/invalid-continue-uri') {
                  // The continue URL provided in the request is invalid.
                } else if (error.code === 'auth/unauthorized-continue-uri') {
                  // The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.
                }
                return error.message;
              },
              cleanupFn: () => {
                /* *
                logger.error(
                  componentName,
                  'firebaseAuth.sendSignInLinkToEmail',
                  'catch error: ' + firebaseAuthError,
                  { code, message }
                );
                /*
                 * codes:
                 * auth/user-not-found
                 * auth/wrong-password
                 */
                dispatch(_toggleSigningIn(false));
                // dispatch(
                //   _setError({
                //     code,
                //     message,
                //   })
                // );
              },
            })
          );
      } else {
        /* *
        logger.error(componentName, 'authenticationSignIn.001');
        /* */
        const errorCode = '001';
        const errorMessage = 'Sign in error';
        makeErrorToast({ message: `code: ${errorCode} - ${errorMessage}` });
        dispatch(_toggleSigningIn(false));
        // dispatch(
        //   _setError({
        //     code: '001',
        //     message: '001',
        //   })
        // );
      }
    } else if (provider) {
      dispatch(_toggleSigningIn(true));
      let firebaseAuthProvider: GoogleAuthProvider | null = null;
      switch (provider) {
        case 'google.com': {
          firebaseAuthProvider = new GoogleAuthProvider();
          break;
        }
        default: {
          // firebaseAuthProvider = new firebaseAuth.GithubAuthProvider();
          // @error
          break;
        }
      }
      if (firebaseAuthProvider) {
        firebaseAuthSignInWithPopup(firebaseAuth, firebaseAuthProvider)
          .then(() => {
            makeSuccessToast({ message: 'Logged in successfully' });
            /* *
            logger.info(componentName, 'authenticationSignIn.firebaseAuth.signInWithPopup.then', {
              user: response?.user,
            });
            /* */
          })
          .catch(
            firebaseErrorHandler({
              message: error => {
                if (error.code === 'auth/account-exists-with-different-credential') {
                  /*
                  Thrown if there already exists an account with the email address asserted by the credential.
                  Resolve this by calling firebase.auth.Auth.fetchSignInMethodsForEmail with the error.email and
                   then asking the user to sign in using one of the returned providers.
                  Once the user is signed in, the original credential retrieved from the error.credential can be linked
                   to the user with firebase.User.linkWithCredential to prevent the user from signing in again
                   to the original provider via popup or redirect.
                  If you are using redirects for sign in, save the credential in session storage and
                   then retrieve on redirect and repopulate the credential using
                   for example firebase.auth.GoogleAuthProvider.credential depending on the credential provider id and complete the link.
                  */
                } else if (error.code === 'auth/auth-domain-config-required') {
                  // Thrown if authDomain configuration is not provided when calling firebase.initializeApp().
                  // Check Firebase Console for instructions on determining and passing that field.
                } else if (error.code === 'auth/cancelled-popup-request') {
                  // Thrown if successive popup operations are triggered.
                  // Only one popup request is allowed at one time.
                  // All the popups would fail with this error except for the last one.
                } else if (error.code === 'auth/operation-not-allowed') {
                  // Thrown if the type of account corresponding to the credential is not enabled.
                  // Enable the account type in the Firebase Console, under the Auth tab.
                } else if (error.code === 'auth/operation-not-supported-in-this-environment') {
                  // Thrown if this operation is not supported in the environment your application is running on.
                  // "location.protocol" must be http or https.
                } else if (error.code === 'auth/popup-blocked') {
                  // Thrown if the popup was blocked by the browser, typically when this operation is triggered outside of a click handler.
                } else if (error.code === 'auth/popup-closed-by-user') {
                  // Thrown if the popup window is closed by the user without completing the sign in to the provider.
                } else if (error.code === 'auth/unauthorized-domain') {
                  // Thrown if the app domain is not authorized for OAuth operations for your Firebase project.
                  // Edit the list of authorized domains from the Firebase console.
                }
                return error.message;
              },
              cleanupFn: () => {
                /* *
                logger.error(componentName, 'firebaseAuth.signInWithPopup.catch', {
                  error,
                });
                * */
                dispatch(_toggleSigningIn(false));
                // dispatch(
                //   _setError({
                //     code: error?.code || '001',
                //     message: error?.message || 'authenticationSignIn error',
                //   })
                // );
              },
            })
          );
      } else {
        makeErrorToast({ message: 'Sign in error. No provider found' });
        dispatch(_toggleSigningIn(false));
        // @error
      }
    } else {
      makeErrorToast({ message: 'Sign in error. No data found' });
      dispatch(_toggleSigningIn(false));
      // @error
    }
  };
};
/**
 * anonymous signing in
 */
export const authenticationSignInAnonymously = ({
  makeSuccessToast,
  firebaseErrorHandler,
}: ToasterContextObj): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    dispatch(_toggleSigningIn(true));
    firebaseAuthSignInAnonymously()
      .then(() => {
        makeSuccessToast({ message: 'Logged in successfully' });
      })
      .catch(
        firebaseErrorHandler({
          message: error => {
            if (error.code === 'auth/operation-not-allowed') {
              // Thrown if anonymous accounts are not enabled.
              // Enable anonymous accounts in the Firebase Console, under the Auth tab.
            }
            return error.message;
          },
          cleanupFn: () => {
            dispatch(_toggleSigningIn(false));
          },
        })
      );
  };
};
/**
 * signing up
 */
export const authenticationSignUp = (
  { makeSuccessToast, makeErrorToast, firebaseErrorHandler }: ToasterContextObj,
  push: any,
  signUpData: SignUpData
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (dispatch, getState) => {
    const { emailAddress, password } = signUpData;
    const currentUser = firebaseAuth.currentUser;
    if (currentUser) {
      if (currentUser.isAnonymous) {
        firebaseLinkAccount({ email: emailAddress, password })
          .then((userCredential: UserCredential) => {
            updateUserInfo(userCredential, dispatch);
            return firestoreUpdateEmail(
              userCredential.user.email,
              getState().authentication.authenticationUser?.userId
            );
          })
          .then(accountCreatedHandler(makeSuccessToast, makeErrorToast, dispatch))
          .catch(firebaseErrorHandler({ message: linkAccountErrorHandler }));
      } else {
        makeErrorToast({ message: 'User is already logged-in. You cannot sign up.' });
        dispatch(_toggleSigningUp(false));
      }
    } else {
      firebaseAuthCreateUserWithEmailAndPassword(firebaseAuth, emailAddress, password)
        .then(() => {
          makeSuccessToast({ message: 'Account created.' });
          dispatch(_toggleSigningIn(true));
          dispatch(_toggleSigningUp(false));
        })
        .catch(
          firebaseErrorHandler({
            message: error => {
              if (error.code === 'auth/email-already-in-use') {
                // Thrown if there already exists an account with the given email address.
              } else if (error.code === 'auth/invalid-email') {
                // Thrown if the email address is not valid.
              } else if (error.code === 'auth/operation-not-allowed') {
                // Thrown if email/password accounts are not enabled.
                // Enable email/password accounts in the Firebase Console, under the Auth tab.
              } else if (error.code === 'auth/weak-password') {
                // Thrown if the password is not strong enough.
              }
              return error.message;
            },
            cleanupFn: () => {
              dispatch(_toggleSigningUp(false));
            },
          })
        );
    }
  };
};
/**
 * sign out
 */
export const authenticationSignOut = (
  { firebaseErrorHandler, makeErrorToast }: ToasterContextObj,
  { cleanupFn, successFn }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async dispatch => {
    const user = firebaseAuth.currentUser;
    if (user?.isAnonymous) {
      dispatch(usersDeleteUser(undefined, { userId: user?.uid }, { successFn, cleanupFn }));
    } else if (user) {
      const clearUpFn = () => {
        badgeWritersDetachUnsubscribe();
        usersDetachFirestoreListener();
        rolesDetachUnsubscribe();
      };
      const clearAndSuccessFn = () => {
        clearUpFn();
        successFn && successFn();
      };
      firebaseAuthSignOut(firebaseAuth)
        .then(clearAndSuccessFn)
        .catch(
          firebaseErrorHandler({
            message: getAdminAuthenticationErrorMessage,
            cleanupFn,
          })
        );
    } else {
      makeErrorToast({ message: 'User already logged out.' });
      cleanupFn && cleanupFn();
    }
    /** */
  };
};
/**
 * update password
 */
export const authenticationUpdatePassword = (
  { makeSuccessToast, firebaseErrorHandler }: ToasterContextObj,
  newPassword: string,
  cancelEditHandler: () => void,
  // eslint-disable-next-line no-unused-vars
  reauthenticateHandler: (op: ProfileTabEdit) => void
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (dispatch, getState) => {
    firebaseUpdatePassword(newPassword)
      .then(() => {
        makeSuccessToast({ message: 'Password update success' });
        let providers = getState().authentication.authenticationProviders;
        if (!providers.includes('password')) {
          providers = ['password', ...providers];
        }
        dispatch(_setProviderData(providers));
        cancelEditHandler();
      })
      .catch(
        firebaseErrorHandler({
          message: (error: FirebaseError) => {
            if (error.code === 'auth/requires-recent-login') {
              reauthenticateHandler('changePassword');
            } else if (error.code === 'auth/weak-password') {
              // Thrown if the password is not strong enough.
              return 'Password is not strong enough';
            }
            return 'Error while updating password. ' + error.message;
          },
        })
      );
  };
};
/**
 * authentication privacy
 *
export const authenticationSetPrivacyMillis = ({
  makeErrorToast,
}: ToasterContextObj): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (_dispatch, getState) => {
    const authenticationUser = getState().authentication.authenticationUser;
    const userId = authenticationUser?.userId || null;
    if (userId) {
      let userUpdateResponse: FirebaseHttpsCallableResult<any> | null = null;
      try {
        userUpdateResponse = await firebaseFunctionsHttpsCallable<
          // done
          { userId: string; privacyMillis: number },
          any
        >(
          firebaseFunctions,
          'userUpdate'
        )({
          userId,
          privacyMillis: Date.now(),
        });
      } catch (error) {
        // @error
        makeErrorToast();
        return;
      }
      if (userUpdateResponse?.data?.error || userUpdateResponse?.data !== true) {
        // @error
        const message = `${userUpdateResponse.data.error}`;
        makeErrorToast({ message });
        return;
      }
      return;
    } else {
      makeErrorToast({ message: 'There was a problem with your account. Please try again' });
    }
  };
};
/**
 * authentication privacy [NEW API]
 */
export const authenticationSetPrivacyMillis = (
  { makeErrorToast, makeSuccessToast }: ToasterContextObj,
  { cleanupFn, successFn }: UtilFunctions
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (_dispatch, getState) => {
    const authenticationUser = getState().authentication.authenticationUser;
    const userId = authenticationUser?.userId || null;
    if (userId) {
      let responseAPI: HttpResponse | null = null;
      try {
        /** new api http request start */
        responseAPI = await api.patch(`/users/${userId}/privacyData`, { millis: Date.now() });
        /** new api http request end */
        const message = responseAPI.data.message;
        if (responseAPI.status >= 400) {
          cleanupFn && cleanupFn();
          makeErrorToast({ message });
        } else {
          makeSuccessToast({ message });
          successFn && successFn();
        }
      } catch {
        cleanupFn && cleanupFn();
        makeErrorToast({ message: 'Error while connecting server...' });
      }
    } else {
      cleanupFn && cleanupFn();
      makeErrorToast({ message: 'There was a problem with your account. Please try again' });
    }
  };
};
/**
 * authentication terms
 *
export const authenticationSetTermsMillis = ({
  makeErrorToast,
}: ToasterContextObj): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (_dispatch, getState) => {
    const { authenticationUser } = getState().authentication;
    const userId = authenticationUser?.userId || null;
    if (userId) {
      let userUpdateResponse: FirebaseHttpsCallableResult<any> | null = null;
      try {
        userUpdateResponse = await firebaseFunctionsHttpsCallable<
          // done
          { userId: string; termsMillis: number },
          any
        >(
          firebaseFunctions,
          'userUpdate'
        )({
          userId,
          termsMillis: Date.now(),
        });
      } catch (error) {
        // @error
        makeErrorToast();
        return;
      }
      if (userUpdateResponse?.data?.error || userUpdateResponse?.data !== true) {
        // @error
        makeErrorToast();
        return;
      }
      return;
    } else {
      makeErrorToast({ message: 'There was a problem with your account. Please try again' });
    }
  };
};
/**
 * authentication terms [NEW API]
 */
export const authenticationSetTermsMillis = (
  { makeErrorToast, makeSuccessToast }: ToasterContextObj,
  { cleanupFn, successFn }: UtilFunctions
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (_dispatch, getState) => {
    const authenticationUser = getState().authentication.authenticationUser;
    const userId = authenticationUser?.userId || null;
    if (userId) {
      let responseAPI: HttpResponse | null = null;
      try {
        /** new api http request start */
        responseAPI = await api.patch(`/users/${userId}/termsData`, { millis: Date.now() });
        /** new api http request end */
        const message = responseAPI.data.message;
        if (responseAPI.status >= 400) {
          cleanupFn && cleanupFn();
          makeErrorToast({ message });
        } else {
          makeSuccessToast({ message });
          successFn && successFn();
        }
      } catch {
        cleanupFn && cleanupFn();
        makeErrorToast({ message: 'Error while connecting server...' });
      }
    } else {
      cleanupFn && cleanupFn();
      makeErrorToast({ message: 'There was a problem with your account. Please try again' });
    }
  };
};
/**
 * AuthenticationI18n
 *
export const authenticationSetI18n = (
  // { makeErrorToast }: ToasterContextObj,
  { countryCode = null, timezone = null, locale = null }: AuthenticationI18n
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const authenticationUser = getState().authentication.authenticationUser;
    const userId = authenticationUser?.userId || null;
    if (userId) {
      let userUpdateResponse: FirebaseHttpsCallableResult<any> | null = null;
      try {
        userUpdateResponse = await firebaseFunctionsHttpsCallable<
          // done
          {
            userId: string;
            countryCode: string | null;
            timezone: string | null;
            locale: string | null;
          },
          any
        >(
          firebaseFunctions,
          'userUpdate'
        )({
          userId,
          countryCode,
          timezone,
          locale,
        });
      } catch (error) {
        // @error
        // makeErrorToast();
        return;
      }
      if (userUpdateResponse?.data?.error || userUpdateResponse?.data !== true) {
        // @error
        // makeErrorToast();
        return;
      }
      return;
    } else {
      // makeErrorToast({ message: 'There was a problem with your account. Please try again' });
    }
  };
};
/**
 * AuthenticationI18n [NEW API]
 */
export const authenticationSetI18n = (
  { makeErrorToast, makeSuccessToast }: ToasterContextObj,
  { countryCode = null, timezone = null, locale = null }: AuthenticationI18n,
  { cleanupFn, successFn }: UtilFunctions
): ThunkAction<void, RootState, any, AnyAction> => {
  return async (_dispatch, getState) => {
    const authenticationUser = getState().authentication.authenticationUser;
    const userId = authenticationUser?.userId || null;
    if (userId) {
      let responseAPI: HttpResponse | null = null;
      try {
        /** new api http request start */
        responseAPI = await api.patch(`/users/${userId}/internalizationData`, {
          countryCode,
          timezone,
          locale,
        });
        /** new api http request end */
        const message = responseAPI.data.message;
        if (responseAPI.status >= 400) {
          makeErrorToast({ message });
          successFn && successFn();
        } else {
          cleanupFn && cleanupFn();
          makeSuccessToast({ message });
        }
      } catch {
        cleanupFn && cleanupFn();
        makeErrorToast({ message: 'Error while connecting server...' });
      }
    } else {
      cleanupFn && cleanupFn();
      makeErrorToast({ message: 'There was a problem with your account. Please try again' });
    }
  };
};
/**
 * verify email
 */
export const emailVerify = (userId?: string) => {
  if (userId) {
    const emailVerified = firebaseAuth.currentUser?.emailVerified || false;
    return firebaseFunctionsHttpsCallable<
      // done
      {
        userId: string;
        emailVerified: boolean;
      },
      any
    >(
      firebaseFunctions,
      'userUpdate'
    )({ userId, emailVerified });
  } else {
    throw new Error('User not authenticated. Please log in again.');
  }
};
/**
 * verify email [NEW API]
 */
export const emailVerifyAPI = (userId?: string) => {
  if (userId) {
    const emailVerified = firebaseAuth.currentUser?.emailVerified || false;
    return api.patch(`/users/${userId}/emailVerifications`, { emailVerified });
  } else {
    throw new Error('User not authenticated. Please log in again.');
  }
};
/**
 * authentication cleanup
 *
 * unsubscribe to auth state changes if the unsubscribe variable `onAuthStateChangeUnsubscribe` has been already initialized
 */
export const authenticationCleanup = () => {
  if (onAuthStateChangeUnsubscribe) {
    onAuthStateChangeUnsubscribe();
  }
};
/*
 * action creators
 */
const _toggleSigningIn = (signingIn?: boolean) => {
  return authenticationActions.toggleSigningIn({ signingIn });
};
export const _toggleSigningUp = (signingUp?: boolean) => {
  return authenticationActions.toggleSigningUp({ signingUp });
};
const _setUser = (user: User | undefined) => {
  const millis = Date.now();
  return authenticationActions.setUser({ user, millis });
};
const _updateUser = (user: UserArgs) => {
  const millis = Date.now();
  return authenticationActions.updateUser({ user, millis });
};
const _setSignInLinkToEmail = (emailAddress: string) => {
  return authenticationActions.setSignInLinkToEmail({ emailAddress });
};
const _setProviderData = (providers: string[]) => {
  return authenticationActions.setProviderData({ providers });
};
export const _addProvider = (providerId: string) => {
  return authenticationActions.addProvider({ provider: providerId });
};
export const _removeProvider = (providerId: string) => {
  return authenticationActions.removeProvider({ provider: providerId });
};
