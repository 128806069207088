/*
 * imports
 */
/* redux imports *
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
/* page import */
import PublicPage from '../PublicPage';
// types
import type { FCWithChildren } from '../../../types';
import type { PageProps as SignedInPageProps } from '../Page/types';
/**
 *  Signed In Page Controller
 */
const SignedInPage: FCWithChildren<SignedInPageProps> = props => {
  /*
   * Redux actions
   *
  const dispatch = useDispatch();
  /*
   *
   */
  const { header, footer, layout, ...restProps } = props;
  const pageLayout = { ...layout, fullWidth: true };
  return (
    <PublicPage layout={pageLayout} header={header} footer={footer}>
      {restProps?.children || null}
    </PublicPage>
  );
};
/*
 * exports
 */
export default SignedInPage;
